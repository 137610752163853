import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./SearchMain.module.css";
import SearchMainHeader from "./SearchMainHeader";
import Header from "./Header";
import Footer from "./Footer";
import ChracterInfo from "./CharacterInfo";
import EquipmentInfo from "./EquipmentInfo";
import RankingAndUnion from "./RankingAndUnion";
import Skill from "./Skill";
import Stat from "./Stat";

function SearchMain() {
  const location = useLocation();

  // localStorage에서 데이터를 가져오기
  const storedData = JSON.parse(localStorage.getItem("mainData")) || {};
  const [mainData, setMainData] = useState(storedData);

  const [activeTab, setActiveTab] = useState("stat");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    // 저장된 데이터가 있을 때만 mainData 업데이트
    if (Object.keys(storedData).length === 0) {
      const locationData = location.state ? { ...location.state } : {};
      setMainData(locationData);
    }
  }, [location.state, storedData]);

  // 컴포넌트가 마운트될 때 localStorage에서 데이터를 가져와서 mainData 업데이트
  useEffect(() => {
    // mainData가 변경될 때마다 localStorage에 저장
    localStorage.setItem("mainData", JSON.stringify(mainData));
  }, [mainData]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}></div>
      <Header></Header>
      <SearchMainHeader
        activeTab={activeTab}
        onTabClick={handleTabClick}
      ></SearchMainHeader>
      {Object.keys(mainData).length > 0 && (
        <>
          {activeTab === "character" && (
            <ChracterInfo mainData={mainData}></ChracterInfo>
          )}
          {activeTab === "stat" && <Stat mainData={mainData}></Stat>}
          {activeTab === "equipment" && (
            <EquipmentInfo mainData={mainData}></EquipmentInfo>
          )}
          {activeTab === "unionAndRanking" && (
            <RankingAndUnion mainData={mainData}></RankingAndUnion>
          )}
          {activeTab === "skill" && <Skill mainData={mainData}></Skill>}
        </>
      )}

      {/* {activeTab === "character" && (
        <ChracterInfo mainData={mainData}></ChracterInfo>
      )}
      {activeTab === "stat" && <Stat mainData={mainData}></Stat>}
      {activeTab === "equipment" && (
        <EquipmentInfo mainData={mainData}></EquipmentInfo>
      )}
      {activeTab === "unionAndRanking" && (
        <RankingAndUnion mainData={mainData}></RankingAndUnion>
      )}

      {activeTab === "skill" && <Skill mainData={mainData}></Skill>} */}
      <Footer />
    </div>
  );
}

export default SearchMain;
