import styles from "./Stat.module.css";
import React from "react";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import ChracterInfo from "./CharacterInfo";

function Stat(props) {
  const mainData = props.mainData;
  const ability = mainData.characterInfo.ability;
  const hyperStat = mainData.characterInfo.hyperStat;
  const stat = mainData.characterInfo.stat;

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  //아래함수는 숫자를 조 억 천만 단위로 표현하기위함
  const numberToKorean = (number) => {
    var inputNumber = number < 0 ? false : number;
    var unitWords = ["", "만 ", "억 ", "조 ", "경 "];
    var splitUnit = 10000;
    var splitCount = unitWords.length;
    var resultArray = [];
    var resultString = "";

    for (var i = 0; i < splitCount; i++) {
      var unitResult =
        (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
      unitResult = Math.floor(unitResult);
      if (unitResult > 0) {
        resultArray[i] = unitResult;
      }
    }

    for (var i = 0; i < resultArray.length; i++) {
      if (!resultArray[i]) continue;
      resultString = String(resultArray[i]) + unitWords[i] + resultString;
    }

    return resultString;
  };

  //   console.log(mainData);

  const [currentPreset, setCurrentPreset] = useState(ability.preset_no);

  const handlePreset = (presetNumber) => {
    setCurrentPreset(presetNumber);
  };

  const currentAbilityPreset = ability[`ability_preset_${currentPreset}`];

  const [currentHyperPreset, setCurrentHyperPreset] = useState(
    hyperStat.use_preset_no
  );

  const handleHyperPreset = (presetNumber) => {
    setCurrentHyperPreset(presetNumber);
  };

  const currentHyperStatPreset =
    hyperStat[`hyper_stat_preset_${currentHyperPreset}`];

  const buttonStyle = (presetNumber) => ({
    border: `${
      presetNumber === currentHyperPreset ? "2px" : "0.5px"
    } solid rgb(223, 223, 223)`,
    cursor: "pointer",
  });

  const buttonStyle2 = (presetNumber) => ({
    border: `${
      presetNumber === currentPreset ? "2px" : "0.5px"
    } solid rgb(223, 223, 223)`,
    cursor: "pointer",
  });

  console.log(mainData);
  return (
    <div className={styles.mainBoxContainer}>
      <div className={styles.mainBox}>
        <ChracterInfo mainData={mainData}></ChracterInfo>
        <div className={styles.statContainer}>
          {!isMobile ? (
            <div className={styles.statBox}>
              <div className={styles.hyperAndTitleBox}>
                <div className={styles.hyperBox}>
                  <div>
                    {!!hyperStat && (
                      <div>
                        {currentHyperStatPreset.map((item, index) => (
                          <div className={styles.hyperEach} key={index}>
                            {item.stat_type} + {item.stat_level}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className={styles.buttonBox}>
                    <div className={styles.buttons}>
                      <button
                        style={buttonStyle(1)}
                        onClick={() => handleHyperPreset(1)}
                      >
                        1
                      </button>
                      <button
                        style={buttonStyle(2)}
                        onClick={() => handleHyperPreset(2)}
                      >
                        2
                      </button>
                      <button
                        style={buttonStyle(3)}
                        onClick={() => handleHyperPreset(3)}
                      >
                        3
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.mainStatBox}>
                <div className={styles.titlebox}>
                  <div>
                    전투력 : {numberToKorean(stat.final_stat[42].stat_value)}
                  </div>
                </div>
                <div className={styles.mainStatBoxInner}>
                  <div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[20].stat_name}</div>
                      <div>{stat.final_stat[20].stat_value}</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[16].stat_name}</div>
                      <div>{stat.final_stat[16].stat_value}</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[18].stat_name}</div>
                      <div>{stat.final_stat[18].stat_value}</div>
                    </div>
                  </div>
                  <div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[21].stat_name}</div>
                      <div>{stat.final_stat[21].stat_value}</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[17].stat_name}</div>
                      <div>{stat.final_stat[17].stat_value}</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[19].stat_name}</div>
                      <div>{stat.final_stat[19].stat_value}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.detailBox}>
                  <div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[1].stat_name}</div>
                      <div>{stat.final_stat[1].stat_value}</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[4].stat_name}</div>
                      <div>{stat.final_stat[4].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[5].stat_name}</div>
                      <div>{stat.final_stat[5].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[40].stat_name}</div>
                      <div>{stat.final_stat[40].stat_value}</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[41].stat_name}</div>
                      <div>{stat.final_stat[41].stat_value}</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[33].stat_name}</div>
                      <div>
                        {stat.final_stat[33].stat_value}초/
                        {stat.final_stat[33].stat_value}%
                      </div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[35].stat_name}</div>
                      <div>{stat.final_stat[35].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[37].stat_name}</div>
                      <div>{stat.final_stat[37].stat_value}%</div>
                    </div>
                  </div>
                  <div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[2].stat_name}</div>
                      <div>{stat.final_stat[2].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[3].stat_name}</div>
                      <div>{stat.final_stat[3].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[32].stat_name}</div>
                      <div>{stat.final_stat[32].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[6].stat_name}</div>
                      <div>{stat.final_stat[6].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[7].stat_name}</div>
                      <div>{stat.final_stat[7].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[30].stat_name}</div>
                      <div>{stat.final_stat[30].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[36].stat_name}</div>
                      <div>{stat.final_stat[36].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[38].stat_name}</div>
                      <div>{stat.final_stat[38].stat_value}%</div>
                    </div>
                  </div>
                </div>
                <div className={styles.detailBox2}>
                  <div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[29].stat_name}</div>
                      <div>{stat.final_stat[29].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[28].stat_name}</div>
                      <div>{stat.final_stat[28].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[39].stat_name}</div>
                      <div>{stat.final_stat[39].stat_value}%</div>
                    </div>
                  </div>
                  <div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[13].stat_name}</div>
                      <div>{stat.final_stat[13].stat_value}</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[14].stat_name}</div>
                      <div>{stat.final_stat[14].stat_value}</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[15].stat_name}</div>
                      <div>{stat.final_stat[15].stat_value}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.abilityBox}>
                <div className={styles.abilityTitle}>
                  <div>어빌리티</div>
                </div>
                <div>
                  {!!ability && (
                    <>
                      <div className={styles.abilityEachBox}>
                        {currentAbilityPreset &&
                          currentAbilityPreset.ability_info &&
                          currentAbilityPreset.ability_info.map(
                            (item, index) => (
                              <div
                                className={`${styles.abilityEach} ${
                                  item.ability_grade === "레전드리"
                                    ? styles.legendary
                                    : item.ability_grade === "유니크"
                                    ? styles.unique
                                    : item.ability_grade === "에픽"
                                    ? styles.epic
                                    : ""
                                }`}
                                key={index}
                              >
                                {item.ability_value}
                              </div>
                            )
                          )}
                      </div>
                      <div className={styles.buttons}>
                        <button
                          style={buttonStyle2(1)}
                          onClick={() => handlePreset(1)}
                        >
                          1
                        </button>
                        <button
                          style={buttonStyle2(2)}
                          onClick={() => handlePreset(2)}
                        >
                          2
                        </button>
                        <button
                          style={buttonStyle2(3)}
                          onClick={() => handlePreset(3)}
                        >
                          3
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.statBox}>
              <div className={styles.mainStatBox}>
                <div className={styles.titlebox}>
                  <div>
                    전투력 : {numberToKorean(stat.final_stat[42].stat_value)}
                  </div>
                </div>
                <div className={styles.mainStatBoxInner}>
                  <div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[20].stat_name}</div>
                      <div>{stat.final_stat[20].stat_value}</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[16].stat_name}</div>
                      <div>{stat.final_stat[16].stat_value}</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[18].stat_name}</div>
                      <div>{stat.final_stat[18].stat_value}</div>
                    </div>
                  </div>
                  <div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[21].stat_name}</div>
                      <div>{stat.final_stat[21].stat_value}</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[17].stat_name}</div>
                      <div>{stat.final_stat[17].stat_value}</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[19].stat_name}</div>
                      <div>{stat.final_stat[19].stat_value}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.detailBox}>
                  <div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[1].stat_name}</div>
                      <div>{stat.final_stat[1].stat_value}</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[4].stat_name}</div>
                      <div>{stat.final_stat[4].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[5].stat_name}</div>
                      <div>{stat.final_stat[5].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[40].stat_name}</div>
                      <div>{stat.final_stat[40].stat_value}</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[41].stat_name}</div>
                      <div>{stat.final_stat[41].stat_value}</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[33].stat_name}</div>
                      <div>
                        {stat.final_stat[33].stat_value}초/
                        {stat.final_stat[33].stat_value}%
                      </div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[35].stat_name}</div>
                      <div>{stat.final_stat[35].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[37].stat_name}</div>
                      <div>{stat.final_stat[37].stat_value}%</div>
                    </div>
                  </div>
                  <div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[2].stat_name}</div>
                      <div>{stat.final_stat[2].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[3].stat_name}</div>
                      <div>{stat.final_stat[3].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[32].stat_name}</div>
                      <div>{stat.final_stat[32].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[6].stat_name}</div>
                      <div>{stat.final_stat[6].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[7].stat_name}</div>
                      <div>{stat.final_stat[7].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[30].stat_name}</div>
                      <div>{stat.final_stat[30].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[36].stat_name}</div>
                      <div>{stat.final_stat[36].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[38].stat_name}</div>
                      <div>{stat.final_stat[38].stat_value}%</div>
                    </div>
                  </div>
                </div>
                <div className={styles.detailBox2}>
                  <div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[29].stat_name}</div>
                      <div>{stat.final_stat[29].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[28].stat_name}</div>
                      <div>{stat.final_stat[28].stat_value}%</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[39].stat_name}</div>
                      <div>{stat.final_stat[39].stat_value}%</div>
                    </div>
                  </div>
                  <div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[13].stat_name}</div>
                      <div>{stat.final_stat[13].stat_value}</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[14].stat_name}</div>
                      <div>{stat.final_stat[14].stat_value}</div>
                    </div>
                    <div className={styles.eachStatBox}>
                      <div>{stat.final_stat[15].stat_name}</div>
                      <div>{stat.final_stat[15].stat_value}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.abilityBox}>
                <div className={styles.abilityTitle}>
                  <div>어빌리티</div>
                </div>
                <div>
                  {!!ability && (
                    <>
                      <div className={styles.abilityEachBox}>
                        {currentAbilityPreset &&
                          currentAbilityPreset.ability_info &&
                          currentAbilityPreset.ability_info.map(
                            (item, index) => (
                              <div
                                className={`${styles.abilityEach} ${
                                  item.ability_grade === "레전드리"
                                    ? styles.legendary
                                    : item.ability_grade === "유니크"
                                    ? styles.unique
                                    : item.ability_grade === "에픽"
                                    ? styles.epic
                                    : ""
                                }`}
                                key={index}
                              >
                                {item.ability_value}
                              </div>
                            )
                          )}
                      </div>
                      <div className={styles.buttons}>
                        <button
                          style={buttonStyle2(1)}
                          onClick={() => handlePreset(1)}
                        >
                          1
                        </button>
                        <button
                          style={buttonStyle2(2)}
                          onClick={() => handlePreset(2)}
                        >
                          2
                        </button>
                        <button
                          style={buttonStyle2(3)}
                          onClick={() => handlePreset(3)}
                        >
                          3
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={styles.hyperAndTitleBox}>
                <div className={styles.hyperBox}>
                  <div>
                    {!!hyperStat && (
                      <div>
                        {currentHyperStatPreset.map((item, index) => (
                          <div className={styles.hyperEach} key={index}>
                            {item.stat_type} + {item.stat_level}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className={styles.buttonBox}>
                    <div className={styles.buttons}>
                      <button
                        style={buttonStyle(1)}
                        onClick={() => handleHyperPreset(1)}
                      >
                        1
                      </button>
                      <button
                        style={buttonStyle(2)}
                        onClick={() => handleHyperPreset(2)}
                      >
                        2
                      </button>
                      <button
                        style={buttonStyle(3)}
                        onClick={() => handleHyperPreset(3)}
                      >
                        3
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Stat;
