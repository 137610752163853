import styles from "./CharacterInfo.module.css";

function ChracterInfo(props) {
  const mainData = props.mainData;
  //아래함수는 숫자를 조 억 천만 단위로 표현하기위함
  const numberToKorean = (number) => {
    var inputNumber = number < 0 ? false : number;
    var unitWords = ["", "만 ", "억 ", "조 ", "경 "];
    var splitUnit = 10000;
    var splitCount = unitWords.length;
    var resultArray = [];
    var resultString = "";

    for (var i = 0; i < splitCount; i++) {
      var unitResult =
        (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
      unitResult = Math.floor(unitResult);
      if (unitResult > 0) {
        resultArray[i] = unitResult;
      }
    }

    for (var i = 0; i < resultArray.length; i++) {
      if (!resultArray[i]) continue;
      resultString = String(resultArray[i]) + unitWords[i] + resultString;
    }

    return resultString;
  };

  const totalArcaneSymbolLevel = mainData.characterInfo.symbolEquipment.symbol
    .filter((item) => item.symbol_name.startsWith("아케인심볼"))
    .reduce((total, item) => total + item.symbol_level, 0);

  const totalAuthenticSymbolLevel =
    mainData.characterInfo.symbolEquipment.symbol
      .filter((item) => item.symbol_name.startsWith("어센틱심볼"))
      .reduce((total, item) => total + item.symbol_level, 0);
  return (
    <div className={styles.mainBoxContainer}>
      <div className={styles.mainBox}>
        <div className={styles.characterInfoBox}>
          <div className={styles.characterImageBox}>
            <img src={mainData.characterInfo.basic.character_image}></img>
          </div>
          <div className={styles.characterSummaryBox}>
            <div className={styles.textBox}>
              <div style={{ marginBottom: "25px" }}>
                <div className={styles.serverImageBox}>
                  <img
                    alt="월드 이미지"
                    src={require(`./img/server/${mainData.characterInfo.basic.world_name}.png`)}
                  ></img>
                  {mainData.characterInfo.basic.world_name}
                </div>
              </div>
              <div className={styles.characterName}>
                {mainData.characterInfo.basic.character_name}
              </div>
              <div className={styles.characterDetail}>
                <div>
                  Lv.{mainData.characterInfo.basic.character_level} |{" "}
                  {mainData.characterInfo.basic.character_class} |{" "}
                  {mainData.characterInfo.dojang.dojang_best_floor}층
                </div>
                <div>
                  인기도 | {mainData.characterInfo.popularity.popularity}
                </div>
                <div>
                  유니온 | Lv.{mainData.characterInfo.union.union_level} |{" "}
                  {mainData.characterInfo.union.union_grade}
                </div>
              </div>
              <div className={styles.chracterMainStatText}>
                <div>전투력</div>
                <div>
                  {numberToKorean(
                    mainData.characterInfo.stat.final_stat[42].stat_value
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.characterSymbolBox}>
            <div className={styles.symbolEquipmentContainer}>
              {mainData.characterInfo.symbolEquipment.symbol.map(
                (item, index) => (
                  <div key={index} className={styles.symbolItem}>
                    <img src={item.symbol_icon} alt={`Symbol ${index + 1}`} />
                    <span>Lv.{item.symbol_level}</span>
                  </div>
                )
              )}
            </div>
            <div className={styles.symbolTextBox}>
              <div className={styles.arcaneForce}>
                ARC + {totalArcaneSymbolLevel * 11}
              </div>
              <div className={styles.authenticForce}>
                AUT + {totalAuthenticSymbolLevel * 10}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChracterInfo;
