import React from "react";
import { Link } from "react-router-dom";

function ErrorPage() {
  return (
    <div>
      에러 발생. 정말 죄송합니다 ㅠㅠ.. 에러페이지 제작중
      <div>
        <Link to="./">메인으로..</Link>
      </div>
    </div>
  );
}

export default ErrorPage;
