import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import styles from "./EquipmentInfo.module.css";
import star from "./img/star.png";
import emptyStar from "./img/emptyStar.png";
import addOption from "./img/환불.png";
import ChracterInfo from "./CharacterInfo";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

function EquipmentInfo(props) {
  const mainData = props.mainData;

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const item_equipment_preset = [
    mainData.characterInfo.itemEquipment.item_equipment_preset_1,
    mainData.characterInfo.itemEquipment.item_equipment_preset_2,
    mainData.characterInfo.itemEquipment.item_equipment_preset_3,
  ];

  const handlePresetChange = (presetNumber) => {
    setCurrentEquipment(item_equipment_preset[presetNumber - 1]);
  };

  const handleCashBaseChange = () => {
    setCurrentCashEquipment(
      mainData.characterInfo.cashitemEquipment.cash_item_equipment_base
    );
  };

  // const currentEquipment = mainData.characterInfo.itemEquipment.item_equipment;

  const [currentEquipment, setCurrentEquipment] = useState(
    mainData.characterInfo.itemEquipment.item_equipment
  );
  const cash_item_equipment_preset = [
    mainData.characterInfo.cashitemEquipment.cash_item_equipment_preset_1,
    mainData.characterInfo.cashitemEquipment.cash_item_equipment_preset_2,
    mainData.characterInfo.cashitemEquipment.cash_item_equipment_preset_3,
  ];

  const [currentCashEquipment, setCurrentCashEquipment] = useState(
    mainData.characterInfo.cashitemEquipment.cash_item_equipment_base
  );

  const handleCashPresetChange = (presetNumber) => {
    setCurrentCashEquipment(cash_item_equipment_preset[presetNumber - 1]);
  };

  //이 함수는 전투력 단위를 표현하기 위한 함수
  const numberToKorean = (number) => {
    var inputNumber = number < 0 ? false : number;
    var unitWords = ["", "만 ", "억 ", "조 ", "경 "];
    var splitUnit = 10000;
    var splitCount = unitWords.length;
    var resultArray = [];
    var resultString = "";

    for (var i = 0; i < splitCount; i++) {
      var unitResult =
        (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
      unitResult = Math.floor(unitResult);
      if (unitResult > 0) {
        resultArray[i] = unitResult;
      }
    }

    for (var i = 0; i < resultArray.length; i++) {
      if (!resultArray[i]) continue;
      resultString = String(resultArray[i]) + unitWords[i] + resultString;
    }

    return resultString;
  };

  const potentialOptionGrade = (x) => {
    const optionGrade = x.potential_option_grade;

    return optionGrade;
  };

  const itemClassName = (x) => {
    if (x === undefined) {
      return styles.fail;
    }

    if (x.potential_option_grade == null) {
      return styles.f;
    }
    if (potentialOptionGrade(x) == null) {
      return styles.f;
    }
    return potentialOptionGrade(x).startsWith("레전드리")
      ? styles.legendaryItem
      : potentialOptionGrade(x).startsWith("유니크")
      ? styles.uniqueItem
      : potentialOptionGrade(x).startsWith("에픽")
      ? styles.epicItem
      : styles.f;
  };

  /* 아이템 선택시 */
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    /* 아이템 선택시 */
  };

  //스텟 추옵 주문서 등등..
  const addOptionString = (x, string) => {
    const upString = string.toUpperCase();
    let returnString = `${upString} : ${x.item_total_option[string]} `;
    if (string === "armor") {
      returnString = `방어력 : ${x.item_total_option[string]} `;
    } else if (string === "attack_power") {
      returnString = `공격력 : ${x.item_total_option[string]} `;
    } else if (string === "magic_power") {
      returnString = `마력 : ${x.item_total_option[string]} `;
    } else if (string === "all_stat") {
      returnString = `올스탯 : ${x.item_total_option[string]}%`;
    } else if (string === "boss_damage") {
      returnString = `보스 데미지 : ${x.item_total_option[string]}%`;
    } else if (string === "damage") {
      returnString = `데미지 : ${x.item_total_option[string]}%`;
    }
    returnString =
      returnString +
      "( " +
      (x.item_base_option[string] !== null &&
      x.item_base_option[string] !== undefined
        ? x.item_base_option[string] + " + "
        : " 0 + ") +
      (x.item_add_option[string] !== null &&
      x.item_add_option[string] !== undefined
        ? x.item_add_option[string] + " + "
        : " 0 + ") +
      (x.item_etc_option[string] !== null &&
      x.item_etc_option[string] !== undefined
        ? x.item_etc_option[string] + " + "
        : " 0 + ") +
      (x.item_starforce_option[string] !== null &&
      x.item_starforce_option[string] !== undefined
        ? x.item_starforce_option[string]
        : " 0 ") +
      " )";

    return returnString;
  };

  //스텟 추옵 주문서 등등..
  const addOptionString2 = (x, string) => {
    const upString = string.toUpperCase();
    let returnString = `${upString} : ${x.item_total_option[string]} `;
    if (string === "armor") {
      returnString = `방어력 : ${x.item_total_option[string]} `;
    } else if (string === "attack_power") {
      returnString = `공격력 : ${x.item_total_option[string]} `;
    } else if (string === "magic_power") {
      returnString = `마력 : ${x.item_total_option[string]} `;
    } else if (string === "all_stat") {
      returnString = `올스탯 : ${x.item_total_option[string]}%`;
    } else if (string === "boss_damage") {
      returnString = `보스 데미지 : ${x.item_total_option[string]}%`;
    } else if (string === "damage") {
      returnString = `데미지 : ${x.item_total_option[string]}%`;
    }
    // returnString =
    //   returnString +
    //   "( " +
    //   (x.item_base_option[string] !== null &&
    //   x.item_base_option[string] !== undefined
    //     ? x.item_base_option[string] + " + "
    //     : " 0 + ") +
    //   (x.item_add_option[string] !== null &&
    //   x.item_add_option[string] !== undefined
    //     ? x.item_add_option[string] + " + "
    //     : " 0 + ") +
    //   (x.item_etc_option[string] !== null &&
    //   x.item_etc_option[string] !== undefined
    //     ? x.item_etc_option[string] + " + "
    //     : " 0 + ") +
    //   (x.item_starforce_option[string] !== null &&
    //   x.item_starforce_option[string] !== undefined
    //     ? x.item_starforce_option[string]
    //     : " 0 ") +
    //   " )";

    let returnString2 = (
      <div style={{ fontSize: "0.78rem" }}>
        {returnString} ({" "}
        {x.item_base_option[string] !== null &&
        x.item_base_option[string] !== undefined ? (
          <span>{x.item_base_option[string]} +</span>
        ) : (
          <>
            <span> 0 </span>
            <span> + </span>
          </>
        )}
        {x.item_add_option[string] !== null &&
        x.item_add_option[string] !== undefined ? (
          <>
            <span style={{ color: "green" }}>
              {" "}
              {x.item_add_option[string]}{" "}
            </span>
            <span> + </span>
          </>
        ) : (
          <>
            <span style={{ color: "green" }}> 0 </span>
            <span> + </span>
          </>
        )}
        {x.item_etc_option[string] !== null &&
        x.item_etc_option[string] !== undefined ? (
          <>
            <span style={{ color: "blue" }}>{x.item_etc_option[string]} </span>
            <span> + </span>
          </>
        ) : (
          <>
            <span style={{ color: "blue" }}> 0 </span>
            <span> + </span>
          </>
        )}
        {x.item_starforce_option[string] !== null &&
        x.item_starforce_option[string] !== undefined ? (
          <>
            <span style={{ color: "orange" }}>
              {x.item_starforce_option[string]}{" "}
            </span>
          </>
        ) : (
          <>
            <span style={{ color: "orange" }}> 0 </span>
          </>
        )}{" "}
        )
      </div>
    );

    return returnString2;
  };

  // 별과 빈별을 생성하는 함수
  const generateStars = (filledCount, totalCount) => {
    const stars = Array.from({ length: totalCount }, (_, index) => (
      <img
        key={index}
        src={index < filledCount ? star : emptyStar}
        alt={`Star ${index + 1}`}
        style={{
          marginRight:
            index % 5 === 5 - 1 && index !== totalCount - 1 ? 6 + "px" : 0,
          width: "12px",
        }}
      />
    ));

    return stars;
  };

  //심볼 선택 위한 useState 변수 + 함수
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const handleSymbolItemClick = (item) => {
    setSelectedSymbol(item);
  };
  //심볼 선택 위한 useState 변수 + 함수

  //추옵 계산 함수
  const addOptionCal = (item) => {
    const str = Number(item.item_add_option.str);
    const dex = Number(item.item_add_option.dex);
    const int = Number(item.item_add_option.int);
    const luk = Number(item.item_add_option.luk);
    const stat = [str, dex, int, luk];
    const attack = item.item_add_option.attack_power;
    const magic = item.item_add_option.magic_power;
    const allstat = item.item_add_option.all_stat;
    let max = 0;
    let maxStat = "";
    let addSum = 0;
    let cnt = 0;
    let statCnt = -1;

    stat.forEach((item) => {
      if (max < item) {
        max = item;
        statCnt = cnt;
      }
      cnt = cnt + 1;
    });
    if (statCnt === 2) {
      addSum = max + Number(magic) * 4 + Number(allstat) * 10;
    } else {
      addSum = max + Number(attack) * 4 + Number(allstat) * 10;
    }
    return addSum;
  };

  // 텍스트 길이 체크
  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      text = text.substring(0, maxLength) + "...";
    }
    return text;
  }

  return (
    <div className={styles.mainBoxContainer}>
      <div className={styles.mainBox}>
        <ChracterInfo mainData={mainData}></ChracterInfo>

        <div className={styles.equipmentAndStatBox}>
          <div className={styles.equipPresetButtonBox}>
            {/* {console.log(item_equipment_preset[0].length)} */}
            {item_equipment_preset.map((preset, index) => (
              <>
                {preset.length !== 0 && (
                  <button
                    key={index}
                    onClick={() => handlePresetChange(index + 1)}
                  >
                    프리셋 {index + 1}
                  </button>
                )}
              </>
            ))}
          </div>
          <div className={styles.equipmentCashEquipButtonBox}>
            {mainData.characterInfo.cashitemEquipment.cash_item_equipment_base
              .length !== 0 && (
              <button onClick={() => handleCashBaseChange()}>베이스</button>
            )}
          </div>
          <div className={styles.equipCashPresetButtonBox}>
            {/* {console.log(item_equipment_preset[0].length)} */}
            {cash_item_equipment_preset.map((preset, index) => (
              <>
                {preset.length !== 0 && (
                  <button
                    key={index}
                    onClick={() => handleCashPresetChange(index + 1)}
                  >
                    프리셋 {index + 1}
                  </button>
                )}
              </>
            ))}
          </div>
          <div className={styles.cashMenuBox}>
            {!!mainData.characterInfo.cashitemEquipment && (
              <>
                {mainData.characterInfo.cashitemEquipment
                  .cash_item_equipment_base.length !== 0 && (
                  <>
                    {currentCashEquipment.map((item, index) => (
                      <div key={index} className={styles.equipMenuItemBox}>
                        <div className={styles.equipMenuImgBox}>
                          <img
                            src={item.cash_item_icon}
                            alt={`Equipment cash item ${index}`}
                          />
                        </div>
                        <div className={styles.equipMenutextBox}>
                          <div className={styles.firstText}>
                            <div className={styles.equipTextBoxStarImg}>
                              <div>{item.cash_item_equipment_part}</div>
                            </div>
                          </div>
                          <div className={styles.secondText}>
                            <div>{truncateText(item.cash_item_name, 12)}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
          <div className={styles.equipImgBox}>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "반지4" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div></div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "모자" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div></div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "엠블렘" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "반지3" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "펜던트2" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "얼굴장식" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div></div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "뱃지" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "반지2" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "펜던트" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "눈장식" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "귀고리" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "훈장" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "반지1" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "무기" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "상의" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "어깨장식" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "보조무기" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "포켓 아이템" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "벨트" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "하의" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "장갑" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "망토" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div>
              {!!mainData.characterInfo.itemEquipment.title && (
                <img
                  src={mainData.characterInfo.itemEquipment.title.title_icon}
                ></img>
              )}
            </div>
            <div></div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "신발" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            <div>
              {!!mainData.characterInfo.androidEquipment && (
                <img
                  src={mainData.characterInfo.androidEquipment.android_icon}
                />
              )}
            </div>
            <div className={styles.itemBox}>
              {currentEquipment.map(
                (item) =>
                  item.item_equipment_slot === "기계 심장" && (
                    <div
                      className={itemClassName(item)}
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.item_icon} alt="아이템 이미지" />
                    </div>
                  )
              )}
            </div>
            {/* <div className={itemClassName(23)}>
                {itemClassName(23) !== styles.fail && (
                  <img
                    src={
                      mainData.characterInfo.itemEquipment.item_equipment[23]
                        .item_icon
                    }
                  />
                )}
              </div> */}
            {/* 여기 고쳐야될거 = 장비 없는 경우엔 표현 안되게 끔 바꿔야함 추가로
              이거도 맵으로 나타내야함 진짜. div에 부위 추가해서 부위 따라가게끔
              로직 짜끼 + 모루 되어있는 경우에는 모루템으로 표기 */}
            {console.log(
              "잠깐테스트 null, undefined는 모두 false임. un,null 이라면? !!두개 달면됨.",
              !!undefined,
              !!null
            )}
          </div>

          <div className={styles.equipMenuBox}>
            {currentEquipment.map((item, index) => (
              <div
                onClick={() => handleItemClick(item)}
                key={index}
                className={styles.equipMenuItemBox}
              >
                <div className={styles.equipMenuImgBox}>
                  <img src={item.item_icon} alt={`Equipment item ${index}`} />
                </div>
                <div className={styles.equipMenutextBox}>
                  <div className={styles.firstText}>
                    {item.starforce !== "0" && (
                      <div className={styles.equipTextBoxStarImg}>
                        <img
                          src={star}
                          style={{ width: "13px", height: "13px" }}
                          alt="starForce img"
                        ></img>

                        <div>{item.starforce}성</div>
                      </div>
                    )}
                    {item.item_equipment_slot !== "무기" && (
                      <>
                        {addOptionCal(item) !== 0 && (
                          <div className={styles.equipTextBoxStarImg}>
                            <img
                              src={addOption}
                              alt="환불이미지"
                              style={{
                                width: "12px",
                              }}
                            />
                            {addOptionCal(item)}
                          </div>
                        )}
                      </>
                    )}

                    {item.potential_option_grade !== null && (
                      <div
                        className={`${styles.equipTextBoxStarImg} ${
                          item.potential_option_grade.startsWith("레전드리")
                            ? styles.legendaryBorder
                            : item.potential_option_grade.startsWith("유니크")
                            ? styles.uniqueBorder
                            : item.potential_option_grade.startsWith("에픽")
                            ? styles.epicBorder
                            : ""
                        }`}
                      >
                        {item.potential_option_grade}
                      </div>
                    )}
                  </div>
                  <div className={styles.secondText}>
                    <div>
                      {/* {item.item_name} */}
                      {truncateText(item.item_name, 12)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.equipInfoBox}>
            {" "}
            {selectedItem ? (
              <div className={styles.options}>
                {selectedItem.starforce !== "0" && (
                  <div>
                    <div className={styles.euipInfoStars}>
                      {generateStars(selectedItem.starforce, 25)}
                    </div>
                    <div className={styles.line}></div>
                  </div>
                )}

                <div className={styles.itemTitleBox}>
                  {selectedItem.soul_name !== null && (
                    <div>
                      {selectedItem.soul_name.replace(" 소울 적용", "")}
                    </div>
                  )}
                  <div>
                    {selectedItem.item_name}
                    {selectedItem.scroll_upgrade &&
                      selectedItem.scroll_upgrade !== "0" && (
                        <> (+{selectedItem.scroll_upgrade})</>
                      )}
                  </div>
                  <div>({selectedItem.potential_option_grade} 아이템)</div>
                </div>
                <div className={styles.itemImageBox}>
                  <img src={selectedItem.item_icon} alt="선택장비 이미지" />
                </div>
                <div className={styles.line}></div>
                <div>장비분류 : {selectedItem.item_equipment_part}</div>
                <div className={styles.line}></div>
                {selectedItem.item_total_option["str"] !== "0" && (
                  <div>{addOptionString2(selectedItem, "str")}</div>
                )}
                {selectedItem.item_total_option["dex"] !== "0" && (
                  <div>{addOptionString2(selectedItem, "dex")}</div>
                )}
                {selectedItem.item_total_option["int"] !== "0" && (
                  <div>{addOptionString2(selectedItem, "int")}</div>
                )}
                {selectedItem.item_total_option["luk"] !== "0" && (
                  <div>{addOptionString2(selectedItem, "luk")}</div>
                )}

                <div className={styles.line}></div>
                {selectedItem.item_total_option["max_hp"] !== "0" && (
                  <div>{addOptionString2(selectedItem, "max_hp")}</div>
                )}

                {selectedItem.item_total_option["max_mp"] !== "0" && (
                  <div>{addOptionString2(selectedItem, "max_mp")}</div>
                )}

                {selectedItem.item_total_option["max_hp"] !== "0" ||
                selectedItem.item_total_option["max_mp"] !== "0" ? (
                  <div className={styles.line}></div>
                ) : null}

                {selectedItem.item_total_option["all_stat"] !== "0" && (
                  <div>{addOptionString2(selectedItem, "all_stat")}</div>
                )}
                {selectedItem.item_total_option["attack_power"] !== "0" && (
                  <div>{addOptionString2(selectedItem, "attack_power")}</div>
                )}
                {selectedItem.item_total_option["magic_power"] !== "0" && (
                  <div>{addOptionString2(selectedItem, "magic_power")}</div>
                )}
                {selectedItem.item_total_option["armor"] !== "0" && (
                  <div>{addOptionString2(selectedItem, "armor")}</div>
                )}

                {selectedItem.item_total_option.boss_damage !== "0" && (
                  // <div>
                  //   보스 공격력 + {selectedItem.item_total_option.boss_damage}%
                  // </div>
                  <div>{addOptionString2(selectedItem, "boss_damage")}</div>
                )}
                {selectedItem.item_total_option.ignore_monster_armor !==
                  "0" && (
                  <div>
                    방어력 무시 +{" "}
                    {selectedItem.item_total_option.ignore_monster_armor}%
                  </div>
                )}
                {selectedItem.item_total_option.damage !== "0" &&
                  addOptionString2(selectedItem, "damage")}
                {selectedItem.item_total_option.equipment_level_decrease !==
                  0 && (
                  <div>
                    착용 레벨 감소 -{" "}
                    {selectedItem.item_total_option.equipment_level_decrease}
                  </div>
                )}

                {selectedItem.item_exceptional_option &&
                  selectedItem.item_exceptional_option.attack_power !== "0" && (
                    <div className={styles.line}></div>
                  )}
                {selectedItem.item_exceptional_option &&
                  selectedItem.item_exceptional_option.attack_power !== "0" && (
                    <div style={{ color: "red" }}>익셉셔널</div>
                  )}
                {selectedItem.item_exceptional_option &&
                  selectedItem.item_exceptional_option.attack_power !== "0" && (
                    <div
                      className={styles.itemExceptionalOption}
                      style={{ fontSize: "0.78rem" }}
                    >
                      STR + {selectedItem.item_exceptional_option.str}
                    </div>
                  )}
                {selectedItem.item_exceptional_option &&
                  selectedItem.item_exceptional_option.attack_power !== "0" && (
                    <div
                      className={styles.itemExceptionalOption}
                      style={{ fontSize: "0.78rem" }}
                    >
                      DEX + {selectedItem.item_exceptional_option.dex}
                    </div>
                  )}
                {selectedItem.item_exceptional_option &&
                  selectedItem.item_exceptional_option.attack_power !== "0" && (
                    <div
                      className={styles.itemExceptionalOption}
                      style={{ fontSize: "0.78rem" }}
                    >
                      INT + {selectedItem.item_exceptional_option.int}
                    </div>
                  )}
                {selectedItem.item_exceptional_option &&
                  selectedItem.item_exceptional_option.attack_power !== "0" && (
                    <div
                      className={styles.itemExceptionalOption}
                      style={{ fontSize: "0.78rem" }}
                    >
                      LUK + {selectedItem.item_exceptional_option.luk}
                    </div>
                  )}
                {selectedItem.item_exceptional_option &&
                  selectedItem.item_exceptional_option.attack_power !== "0" && (
                    <div
                      className={styles.itemExceptionalOption}
                      style={{ fontSize: "0.78rem" }}
                    >
                      MAX_HP + {selectedItem.item_exceptional_option.max_hp}
                    </div>
                  )}
                {selectedItem.item_exceptional_option &&
                  selectedItem.item_exceptional_option.attack_power !== "0" && (
                    <div
                      className={styles.itemExceptionalOption}
                      style={{ fontSize: "0.78rem" }}
                    >
                      MAX_MP + {selectedItem.item_exceptional_option.max_mp}
                    </div>
                  )}

                {selectedItem.item_exceptional_option &&
                  selectedItem.item_exceptional_option.attack_power !== "0" && (
                    <div
                      className={styles.itemExceptionalOption}
                      style={{ fontSize: "0.78rem" }}
                    >
                      공격력 +{" "}
                      {selectedItem.item_exceptional_option.attack_power}
                    </div>
                  )}
                {selectedItem.item_exceptional_option &&
                  selectedItem.item_exceptional_option.attack_power !== "0" && (
                    <div
                      className={styles.itemExceptionalOption}
                      style={{ fontSize: "0.78rem" }}
                    >
                      마력 + {selectedItem.item_exceptional_option.magic_power}
                    </div>
                  )}

                {selectedItem.cuttable_count !== "255" && (
                  <div>
                    <div className={styles.line} />
                    <div>
                      가위 사용 가능 횟수 : {selectedItem.cuttable_count}
                    </div>
                  </div>
                )}

                {selectedItem.potential_option_1 !== null && (
                  <div>
                    <div className={styles.line}></div>
                    <div
                      style={{ marginBottom: "3px" }}
                      className={`${
                        selectedItem.potential_option_grade.startsWith(
                          "레전드리"
                        )
                          ? styles.legendary
                          : selectedItem.potential_option_grade.startsWith(
                              "유니크"
                            )
                          ? styles.unique
                          : selectedItem.potential_option_grade.startsWith(
                              "에픽"
                            )
                          ? styles.epic
                          : ""
                      }`}
                    >
                      잠재 능력
                    </div>
                    <div>{selectedItem.potential_option_1}</div>
                    <div>{selectedItem.potential_option_2}</div>
                    {selectedItem.potential_option_3 !== null && (
                      <div>{selectedItem.potential_option_3}</div>
                    )}
                  </div>
                )}

                {selectedItem.additional_potential_option_1 !== null && (
                  <div>
                    <div className={styles.line}></div>
                    <div
                      style={{ marginBottom: "3px" }}
                      className={`${
                        selectedItem.additional_potential_option_grade.startsWith(
                          "레전드리"
                        )
                          ? styles.legendary
                          : selectedItem.additional_potential_option_grade.startsWith(
                              "유니크"
                            )
                          ? styles.unique
                          : selectedItem.additional_potential_option_grade.startsWith(
                              "에픽"
                            )
                          ? styles.epic
                          : ""
                      }`}
                    >
                      에디셔널 잠재 능력
                    </div>
                    <div>{selectedItem.additional_potential_option_1}</div>
                    <div>{selectedItem.additional_potential_option_2}</div>
                    {selectedItem.additional_potential_option_3 !== null && (
                      <div>{selectedItem.additional_potential_option_3}</div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <p>
                장비 아이템을 클릭(터치)하여 <br /> 정보를 확인하세요.
              </p>
            )}
          </div>
        </div>

        <div className={styles.petContainer}>
          <div className={styles.petContainerTitle}>
            <div>장착 펫 정보</div>
          </div>
          <div className={styles.petContainerBox}>
            {mainData.characterInfo.petEquipment !== undefined && (
              <>
                {mainData.characterInfo.petEquipment.pet_1_appearance !==
                  null && (
                  <div className={styles.petBox}>
                    <div className={styles.petImgName}>
                      <div>
                        <img
                          src={
                            mainData.characterInfo.petEquipment
                              .pet_1_appearance_icon
                          }
                          alt="펫 이미지"
                        ></img>
                      </div>
                      <div>
                        {mainData.characterInfo.petEquipment.pet_1_name}{" "}
                      </div>
                    </div>
                    <div className={styles.line}></div>
                    <div className={styles.petSkills}>
                      {mainData.characterInfo.petEquipment.pet_1_auto_skill !==
                      null ? (
                        <div>
                          {mainData.characterInfo.petEquipment.pet_1_auto_skill
                            .skill_1_icon !== null && (
                            <div>
                              <img
                                src={
                                  mainData.characterInfo.petEquipment
                                    .pet_1_auto_skill.skill_1_icon
                                }
                                alt="펫 자동스킬"
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {mainData.characterInfo.petEquipment.pet_1_auto_skill !==
                      null ? (
                        <div>
                          {mainData.characterInfo.petEquipment.pet_1_auto_skill
                            .skill_2_icon !== null && (
                            <div>
                              <img
                                src={
                                  mainData.characterInfo.petEquipment
                                    .pet_1_auto_skill.skill_2_icon
                                }
                                alt="펫 자동스킬"
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div> </div>
                      )}
                    </div>
                  </div>
                )}

                {mainData.characterInfo.petEquipment.pet_2_appearance !==
                  null && (
                  <div className={styles.petBox}>
                    <div className={styles.petImgName}>
                      <div>
                        <img
                          src={
                            mainData.characterInfo.petEquipment
                              .pet_2_appearance_icon
                          }
                          alt="펫 이미지"
                        ></img>
                      </div>
                      <div>
                        {mainData.characterInfo.petEquipment.pet_2_name}{" "}
                      </div>
                    </div>
                    <div className={styles.line}></div>
                    <div className={styles.petSkills}>
                      {mainData.characterInfo.petEquipment.pet_2_auto_skill !==
                      null ? (
                        <div>
                          {mainData.characterInfo.petEquipment.pet_2_auto_skill
                            .skill_1_icon !== null && (
                            <div>
                              <img
                                src={
                                  mainData.characterInfo.petEquipment
                                    .pet_2_auto_skill.skill_1_icon
                                }
                                alt="펫 자동스킬"
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {mainData.characterInfo.petEquipment.pet_2_auto_skill !==
                      null ? (
                        <div>
                          {mainData.characterInfo.petEquipment.pet_2_auto_skill
                            .skill_2_icon !== null && (
                            <div>
                              <img
                                src={
                                  mainData.characterInfo.petEquipment
                                    .pet_2_auto_skill.skill_2_icon
                                }
                                alt="펫 자동스킬"
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                )}

                {mainData.characterInfo.petEquipment.pet_3_appearance !==
                  null && (
                  <div className={styles.petBox}>
                    <div className={styles.petImgName}>
                      <div>
                        <img
                          src={
                            mainData.characterInfo.petEquipment
                              .pet_3_appearance_icon
                          }
                          alt="펫 이미지"
                        ></img>
                      </div>
                      <div>
                        {mainData.characterInfo.petEquipment.pet_3_name}{" "}
                      </div>
                    </div>
                    <div className={styles.line}></div>
                    <div className={styles.petSkills}>
                      {mainData.characterInfo.petEquipment.pet_3_auto_skill !==
                        null && (
                        <div>
                          {mainData.characterInfo.petEquipment.pet_3_auto_skill
                            .skill_1_icon !== null && (
                            <div>
                              <img
                                src={
                                  mainData.characterInfo.petEquipment
                                    .pet_3_auto_skill.skill_1_icon
                                }
                                alt="펫 자동스킬"
                              />
                            </div>
                          )}
                        </div>
                      )}
                      {mainData.characterInfo.petEquipment.pet_3_auto_skill !==
                        null && (
                        <div>
                          {mainData.characterInfo.petEquipment.pet_3_auto_skill
                            .skill_2_icon !== null && (
                            <div>
                              <img
                                src={
                                  mainData.characterInfo.petEquipment
                                    .pet_3_auto_skill.skill_2_icon
                                }
                                alt="펫 자동스킬"
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {console.log(
            "z",
            mainData.characterInfo.petEquipment.pet_2_auto_skill
          )}
        </div>
        <div className={styles.symbolBox}>
          <div className={styles.symbolImgBox}>
            <div className={styles.symbolTitle}>
              <div>장착 심볼 정보</div>
            </div>
            <div className={styles.arcaneSymbolBox}>
              {mainData.characterInfo.symbolEquipment.symbol ? (
                <>
                  {mainData.characterInfo.symbolEquipment.symbol
                    .filter((item) => item.symbol_name.startsWith("아케인심볼"))
                    .map((item, index) => (
                      <div
                        key={index}
                        className={styles.symbolItem}
                        onClick={() => handleSymbolItemClick(item)}
                      >
                        <img
                          src={item.symbol_icon}
                          alt={`Symbol ${index + 1}`}
                        />
                        <div className={styles.line}></div>
                        <span>Lv.{item.symbol_level}</span>
                      </div>
                    ))}
                </>
              ) : null}
            </div>
            <div className={styles.line}></div>
            <div className={styles.arcaneSymbolBox}>
              {mainData.characterInfo.symbolEquipment.symbol ? (
                <>
                  {mainData.characterInfo.symbolEquipment.symbol
                    .filter((item) => item.symbol_name.startsWith("어센틱심볼"))
                    .map((item, index) => (
                      <div
                        key={index}
                        className={styles.symbolItem}
                        onClick={() => handleSymbolItemClick(item)}
                      >
                        <img
                          src={item.symbol_icon}
                          alt={`Symbol ${index + 1}`}
                        />
                        <div className={styles.line}></div>
                        <span>Lv.{item.symbol_level}</span>
                      </div>
                    ))}
                </>
              ) : null}
            </div>
          </div>
          <div className={styles.symbolTextBox}>
            <div>
              {selectedSymbol && (
                <>
                  <p>{selectedSymbol.symbol_name}</p>
                  <p>레벨: {selectedSymbol.symbol_level}</p>
                  {selectedSymbol.symbol_level === 20 ? (
                    <p>성장이 완료 되었습니다.</p>
                  ) : (
                    <p>
                      exp: {selectedSymbol.symbol_growth_count} /{" "}
                      {selectedSymbol.symbol_require_growth_count}
                    </p>
                  )}
                  {selectedSymbol.symbol_str !== "0" && (
                    <p>STR + {selectedSymbol.symbol_str}</p>
                  )}
                  {selectedSymbol.symbol_dex !== "0" && (
                    <p>DEX + {selectedSymbol.symbol_dex}</p>
                  )}
                  {selectedSymbol.symbol_int !== "0" && (
                    <p>INT + {selectedSymbol.symbol_int}</p>
                  )}
                  {selectedSymbol.symbol_luk !== "0" && (
                    <p>LUK + {selectedSymbol.symbol_luk}</p>
                  )}
                  {selectedSymbol.symbol_hp !== "0" && (
                    <p>HP + {selectedSymbol.symbol_hp}</p>
                  )}
                  {selectedSymbol.symbol_name.startsWith("어센틱심볼") && (
                    <>
                      {selectedSymbol.symbol_level !== 11 && (
                        <p>
                          남은 갯수:{" "}
                          {selectedSymbol.symbol_require_growth_count -
                            selectedSymbol.symbol_growth_count}
                        </p>
                      )}

                      {/* 하루에 얻을 수 있는 갯수와 며칠이 필요한지 계산 */}
                      {selectedSymbol.symbol_name ===
                      "어센틱심볼 : 세르니움" ? (
                        <p>
                          일일 퀘스트를{" "}
                          {Math.ceil(
                            (selectedSymbol.symbol_require_growth_count -
                              selectedSymbol.symbol_growth_count) /
                              20
                          )}
                          일 진행하면 심볼 레벨을 올릴 수 있어요!
                        </p>
                      ) : (
                        <p>
                          일일 퀘스트를{" "}
                          {Math.ceil(
                            (selectedSymbol.symbol_require_growth_count -
                              selectedSymbol.symbol_growth_count) /
                              10
                          )}
                          일 진행하면 심볼 레벨을 올릴 수 있어요!
                        </p>
                      )}
                    </>
                  )}
                </>
              )}
              {/* 선택된 심볼이 없을 때의 내용 */}
              {!selectedSymbol && (
                <p>
                  심볼을 클릭하여 정보를 확인하세요.
                  <br /> 어센틱 심볼은 레벨업에 몇번의 일일퀘스트가 필요한지 알
                  수 있어요.
                </p>
              )}
            </div>
          </div>
        </div>
        <div className={styles.characterSetInfoContainer}>
          <div className={styles.characterSetInfoBox}>
            <div className={styles.characterSetInfoTitleBox}>
              <div>장착 효과</div>
            </div>
            <div className={styles.flexBox}>
              {mainData.characterInfo.setEffect.set_effect.map(
                (item, index) => (
                  <div key={index} className={styles.setItemBox}>
                    <div className={styles.setItemImgBox}>
                      <img
                        src={
                          "https://open.api.nexon.com/static/maplestory/ItemIcon/KEPCIPOB.png"
                        }
                        alt="이미지 예시"
                      ></img>
                    </div>
                    <div className={styles.setItemTextBox}>
                      <div className={styles.setItemTextEachBox}>
                        <span>{item.set_name}</span>
                        <span>　{item.total_set_count}set</span>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      {console.log(mainData.characterInfo)}
    </div>
  );
}

export default EquipmentInfo;
