import styles from "./SearchMainHeader.module.css";
function SearchMainHeader({ onTabClick, activeTab }) {
  return (
    <div className={styles.headerContainer}>
      <div
        onClick={() => onTabClick("stat")}
        className={activeTab === "stat" ? styles.activeTab : ""}
      >
        스탯 정보
      </div>
      <div
        onClick={() => onTabClick("equipment")}
        className={activeTab === "equipment" ? styles.activeTab : ""}
      >
        장비 정보
      </div>
      <div
        onClick={() => onTabClick("skill")}
        className={activeTab === "skill" ? styles.activeTab : ""}
      >
        스킬 정보
      </div>
      <div
        onClick={() => onTabClick("unionAndRanking")}
        className={activeTab === "unionAndRanking" ? styles.activeTab : ""}
      >
        유니온 및 랭킹
      </div>
    </div>
  );
}

export default SearchMainHeader;
