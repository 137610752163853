import styles from "./UnionTetris.module.css";
import Header from "./Header";
import Footer from "./Footer";
import { useState, useEffect } from "react";

function UnionTetris() {
  const [boardState, setBoardState] = useState({});
  const [isMouseDown, setIsMouseDown] = useState(false);

  const handleMouseDown = () => {
    setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleMouseEnter = (x, y) => {
    // 드래그 중일 때만 셀을 클릭한 것으로 처리
    if (isMouseDown) {
      handleCellClick(x, y);
    }
  };

  const handleCellClick = (x, y) => {
    // 현재 셀의 상태를 검은색에서 하얀색 또는 하얀색에서 검은색으로 변경
    const cellKey = `${x}-${y}`;
    const updatedBoardState = { ...boardState };

    if (updatedBoardState[cellKey] === "black") {
      // 검은색이면 하얀색으로 변경
      delete updatedBoardState[cellKey];
    } else {
      // 하얀색 또는 색상이 없으면 검은색으로 변경
      updatedBoardState[cellKey] = "black";
    }

    // 변경된 상태를 적용
    setBoardState(updatedBoardState);
  };

  useEffect(() => {
    // 마우스가 화면을 벗어났을 때 드래그 상태 초기화
    const handleMouseLeave = () => {
      setIsMouseDown(false);
    };

    document.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      document.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  console.log(boardState);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}></div>
      <Header></Header>
      <div className={styles.mainBoxContainer}>
        <div className={styles.mainBox}>
          <div>
            <div
              className={styles.unionTetris}
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(22, 20px)",
              }}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              {/* 바둑판 생성 */}
              {Array.from({ length: 20 }, (_, y) =>
                Array.from({ length: 22 }, (_, x) => (
                  <div
                    key={`${x - 11}-${-y + 9}`}
                    style={{
                      width: "20px",
                      height: "20px",
                      border: "1px solid #000",
                      backgroundColor:
                        boardState[`${x - 11}-${-y + 9}`] || "white",
                    }}
                    onClick={() => handleCellClick(x - 11, -y + 9)}
                    onMouseEnter={() => handleMouseEnter(x - 11, -y + 9)}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default UnionTetris;
