import styles from "./Footer.module.css";
import caring from "./img/카링우산.png";
function Footer() {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerContents}>
        <div className={styles.sightName}>
          <h2>KARING.KR</h2>
          <img src={caring}></img>
        </div>
        <div className={styles.copyRight}>
          <p>Design by @kyuchory</p>
          <p>© 2024 karing.kr All rights reserved.</p>
          <p>
            Data sourced from NEXON OpenAPI. Font by Maplestory. Image by
            Maplestory.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
