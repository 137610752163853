import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import styles from "./FindCharacter.module.css";
import search from "./img/search.png";
import { useState } from "react";

function FindCharacter() {
  const [characterName, setCharacterName] = useState(); //부캐 닉네임
  const [basic, setBasic] = useState(); //부캐 기본정보
  const [findCharacter, setFindCharacter] = useState(); //찾은 본캐 유니온 정보
  const [findBasic, setFindBasic] = useState(); //찾은 본캐 기본 정보

  const [characterInfo, setCharacterInfo] = useState({}); //본캐 정보만.

  const onChange = (e) => {
    const characterName = e.target.value;
    setCharacterName(characterName);
  };
  const onSubmit = (e) => {
    e.preventDefault();

    // 폼 제출 시 fetchData 함수 호출
    fetchData();
  };
  //전날 계산기
  const getYesterdayDate = () => {
    // 현재 날짜 객체 생성
    const currentDate = new Date();

    // 어제 날짜 계산
    const yesterdayDate = new Date(currentDate);
    yesterdayDate.setDate(currentDate.getDate() - 1);

    // 어제 날짜를 YYYY-MM-DD 형식으로 변환
    const formattedYesterday = yesterdayDate.toISOString().split("T")[0];

    return formattedYesterday;
  };
  const fetchData = async () => {
    const API_KEY =
      "live_0abd29e8fd27df68d1d7480486e297e6fdb7398d70d660ca4d039666239cc22a0f700f739f6a5e25d3165ce8ceb4e20a";
    try {
      // 첫 번째 API 호출 ( ocid )
      const response1 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/id?character_name=${characterName}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const ocidData = await response1.json();
      if (ocidData.error) {
        alert("캐릭터를 찾을 수 없어요 ㅠ");
        console.log("에러 발생. fetchData를 중지합니다.");
        return;
      } else {
        // alert("캐릭터 정보를 로딩중이에요~");
      }
      const ocid = ocidData.ocid;
      console.log("ocid=>", ocid);
      const yesterdayDate = getYesterdayDate();
      console.log("yesterdayDate=>", yesterdayDate);

      // 두 번째 API 호출 (부캐릭 기본 정보)
      const response2 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/basic?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );

      const basic = await response2.json();
      console.log("basic =>", basic);
      setBasic(basic);

      //유니온랭킹 정보
      const response3 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/ranking/union?date=${yesterdayDate}&world_name=${basic.world_name}&ocid=${ocid}&page=1`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const findCharacter = await response3.json();
      setFindCharacter(findCharacter);

      if (findCharacter !== null && findCharacter !== undefined) {
        const response4 = await fetch(
          `https://open.api.nexon.com/maplestory/v1/id?character_name=${findCharacter.ranking[0].character_name}`,
          {
            headers: {
              "x-nxopen-api-key": API_KEY,
            },
          }
        );
        const ocidData2 = await response4.json();
        const ocid2 = ocidData2.ocid;

        //본캐릭 정보
        const response5 = await fetch(
          `https://open.api.nexon.com/maplestory/v1/character/basic?ocid=${ocid2}&date=${yesterdayDate}`,
          {
            headers: {
              "x-nxopen-api-key": API_KEY,
            },
          }
        );
        const findBasic = await response5.json();
        setFindBasic(findBasic);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}></div>
      <Header></Header>
      <div className={styles.mainBoxContainer}>
        <div className={styles.mainBox}>
          <div className={styles.inputContainer}>
            <div className={styles.inputTitle}>
              <div>부캐 캐릭터 명을 입력해보세요!</div>
            </div>
            <div className={styles.inputFormBox}>
              <form onSubmit={onSubmit}>
                <input
                  className={styles.inputField}
                  placeholder="부캐 캐릭터 명을 입력해보세요!"
                  onChange={onChange}
                />
                {/* 추가: 폼 제출 시 fetchData 호출 */}
                <button type="submit" style={{ display: "none" }}></button>
              </form>
              <img
                className={styles.searchIcon}
                src={search}
                onClick={onSubmit}
                alt="검색 아이콘"
              ></img>
            </div>
          </div>
          <div className={styles.findCharacterContainer}>
            <div className={styles.findCharacterTitle}>
              <div>본캐 정보</div>
            </div>
            <div className={styles.findCharacterBox}>
              {findBasic && (
                <div>
                  <div className={styles.findCharacterImg}>
                    <img src={findBasic.character_image} alt="본캐이미지"></img>
                  </div>
                  <div>길드 : {findBasic.character_guild_name}</div>
                </div>
              )}
              {findCharacter && findCharacter.ranking.length !== 0 && (
                <div>
                  <div>
                    본캐 닉네임 : {findCharacter.ranking[0].character_name}
                  </div>
                  <div>직업 : {findCharacter.ranking[0].class_name}</div>
                  <div>서버 : {findCharacter.ranking[0].world_name}</div>
                  <div>유니온 Lv.{findCharacter.ranking[0].union_level}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FindCharacter;
