import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import SearchMain from "./componets/SearchMain";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import FindCharacter from "./componets/FindCharacter";
import UnionTetris from "./componets/UnionTetris";
import ErrorPage from "./ErrorPage";

// const router = createBrowserRouter([
//   basename : '/your-basename',
//   {
//     path: "/",
//     element: <App />,
//   },
//   {
//     path: "/search",
//     element: <SearchMain />,
//   },
// ]);

// const router = createBrowserRouter({
//   basename: "http://kyuchory.github.io/chory.gg/", // Add your desired basename here
//   routes: [
//     {
//       path: "/",
//       element: <App />,
//     },
//     {
//       path: "/search",
//       element: <SearchMain />,
//     },
//   ],
// });

// const routes =

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <App />,
    },
    {
      path: "/search",
      element: <SearchMain />,
    },
    {
      path: "/findCharacter",
      element: <FindCharacter />,
    },
    {
      path: "/unionTetris",
      element: <UnionTetris />,
    },
    {
      path: "/*",
      element: <ErrorPage />,
    },
  ],
  {
    basename: `${process.env.PUBLIC_URL}`,
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
