import styles from "./Main.module.css";
import search from "./img/search.png";

function Main({ onCharacterNameChange, onSubmit }) {
  const onChange = (e) => {
    const characterName = e.target.value;
    onCharacterNameChange(characterName);
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.contentsBox}>
        <div className={styles.searchBar}>
          <form onSubmit={onSubmit}>
            <input
              className={styles.inputField}
              placeholder="애정가득한 캐릭터명을 입력해보세요."
              onChange={onChange}
            />
            {/* 추가: 폼 제출 시 fetchData 호출 */}
            <button type="submit" style={{ display: "none" }}></button>
          </form>
          <img
            className={styles.searchIcon}
            src={search}
            onClick={onSubmit}
            alt="검색 아이콘"
          ></img>
        </div>
      </div>
    </div>
  );
}

export default Main;
