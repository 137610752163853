import styles from "./RankingAndUnion.module.css";
import { useEffect, useState } from "react";
import img from "./img/union/그랜드 마스터 유니온 1.webp";
import ChracterInfo from "./CharacterInfo";
import { useMediaQuery } from "react-responsive";

function RankingAndUnion(props) {
  const mainData = props.mainData;

  const unionArtifact = mainData.characterInfo.unionArtifact;

  const unionRaider = mainData.characterInfo.unionRaider;

  console.log(unionRaider);

  const [boardState, setBoardState] = useState({});

  const [selectedArtifactInfo, setSelectedArtifactInfo] = useState(null);

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const handleArtifactClick0 = () => {
    if (
      unionArtifact &&
      unionArtifact.union_artifact_crystal &&
      unionArtifact.union_artifact_crystal.length > 0 &&
      unionArtifact.union_artifact_crystal[0] !== null &&
      unionArtifact.union_artifact_crystal[0] !== undefined
    ) {
      setSelectedArtifactInfo(unionArtifact.union_artifact_crystal[0]);
    }
  };
  const handleArtifactClick1 = () => {
    if (
      unionArtifact &&
      unionArtifact.union_artifact_crystal &&
      unionArtifact.union_artifact_crystal.length > 0 &&
      unionArtifact.union_artifact_crystal[1] !== null &&
      unionArtifact.union_artifact_crystal[1] !== undefined
    ) {
      setSelectedArtifactInfo(unionArtifact.union_artifact_crystal[1]);
    }
  };
  const handleArtifactClick2 = () => {
    if (
      unionArtifact &&
      unionArtifact.union_artifact_crystal &&
      unionArtifact.union_artifact_crystal.length > 0 &&
      unionArtifact.union_artifact_crystal[2] !== null &&
      unionArtifact.union_artifact_crystal[2] !== undefined
    ) {
      setSelectedArtifactInfo(unionArtifact.union_artifact_crystal[2]);
    }
  };
  const handleArtifactClick3 = () => {
    if (
      unionArtifact &&
      unionArtifact.union_artifact_crystal &&
      unionArtifact.union_artifact_crystal.length > 0 &&
      unionArtifact.union_artifact_crystal[3] !== null &&
      unionArtifact.union_artifact_crystal[3] !== undefined
    ) {
      setSelectedArtifactInfo(unionArtifact.union_artifact_crystal[3]);
    }
  };
  const handleArtifactClick4 = () => {
    if (
      unionArtifact &&
      unionArtifact.union_artifact_crystal &&
      unionArtifact.union_artifact_crystal.length > 0 &&
      unionArtifact.union_artifact_crystal[4] !== null &&
      unionArtifact.union_artifact_crystal[4] !== undefined
    ) {
      setSelectedArtifactInfo(unionArtifact.union_artifact_crystal[4]);
    }
  };
  const handleArtifactClick5 = () => {
    if (
      unionArtifact &&
      unionArtifact.union_artifact_crystal &&
      unionArtifact.union_artifact_crystal.length > 0 &&
      unionArtifact.union_artifact_crystal[5] !== null &&
      unionArtifact.union_artifact_crystal[5] !== undefined
    ) {
      setSelectedArtifactInfo(unionArtifact.union_artifact_crystal[5]);
    }
  };
  const handleArtifactClick6 = () => {
    if (
      unionArtifact &&
      unionArtifact.union_artifact_crystal &&
      unionArtifact.union_artifact_crystal.length > 0 &&
      unionArtifact.union_artifact_crystal[6] !== null &&
      unionArtifact.union_artifact_crystal[6] !== undefined
    ) {
      setSelectedArtifactInfo(unionArtifact.union_artifact_crystal[6]);
    }
  };
  const handleArtifactClick7 = () => {
    if (
      unionArtifact &&
      unionArtifact.union_artifact_crystal &&
      unionArtifact.union_artifact_crystal.length > 0 &&
      unionArtifact.union_artifact_crystal[7] !== null &&
      unionArtifact.union_artifact_crystal[7] !== undefined
    ) {
      setSelectedArtifactInfo(unionArtifact.union_artifact_crystal[7]);
    }
  };
  const handleArtifactClick8 = () => {
    if (
      unionArtifact &&
      unionArtifact.union_artifact_crystal &&
      unionArtifact.union_artifact_crystal.length > 0 &&
      unionArtifact.union_artifact_crystal[8] !== null &&
      unionArtifact.union_artifact_crystal[8] !== undefined
    ) {
      setSelectedArtifactInfo(unionArtifact.union_artifact_crystal[8]);
    }
  };

  useEffect(() => {
    // mainData에서 좌표를 추출하여 바둑판에 채우기
    if (
      mainData.characterInfo &&
      mainData.characterInfo.unionRaider &&
      mainData.characterInfo.unionRaider.union_block
    ) {
      const blocks = mainData.characterInfo.unionRaider.union_block;

      const newBoardState = {};
      blocks.forEach((block) => {
        if (block.block_position) {
          block.block_position.forEach((coord) => {
            const key = `${coord.x}-${coord.y - 1}`;
            newBoardState[key] = "rgb(180, 180, 180)";
          });
        }
      });

      setBoardState(newBoardState);
    }
  }, [mainData]);

  return (
    <div className={styles.mainBoxContainer}>
      <div className={styles.mainBox}>
        <ChracterInfo mainData={mainData}></ChracterInfo>
        <div className={styles.rankingBox}>
          <div className={styles.unionBox}>
            <div className={styles.eachImgbox}>
              <img
                src={require(`./img/union/${mainData.characterInfo.union.union_grade}.webp`)}
                alt="유니온 이미지"
              />
            </div>
            <div className={styles.line}></div>
            <div>Lv. {mainData.characterInfo.union.union_level}</div>
            <div>{mainData.characterInfo.union.union_grade}</div>
            <div>
              {mainData.characterInfo.unionRanking.ranking.length !== 0 && (
                <div>
                  유니온 랭킹 :{" "}
                  {mainData.characterInfo.unionRanking.ranking[0].ranking} 위
                </div>
              )}
            </div>
          </div>
          <div className={styles.worldAndClassRankingBox}>
            <div className={styles.eachImgbox}>
              <img
                src={mainData.characterInfo.basic.character_image}
                alt="캐릭터 이미지"
              ></img>
            </div>
            <div className={styles.line}></div>
            <>
              {mainData.characterInfo.classRanking.ranking.length !== 0 ? (
                <>
                  <div>
                    {
                      mainData.characterInfo.classRanking.ranking[0]
                        .character_name
                    }
                  </div>
                  <div>
                    Lv.{" "}
                    {
                      mainData.characterInfo.classRanking.ranking[0]
                        .character_level
                    }
                  </div>
                  <div>
                    {mainData.characterInfo.classRanking.ranking[0].class_name}_
                    {
                      mainData.characterInfo.classRanking.ranking[0]
                        .sub_class_name
                    }
                  </div>
                  <div>
                    월드 랭킹 :{" "}
                    {mainData.characterInfo.wholeRanking.ranking[0].ranking} 위
                  </div>
                  <div>
                    월드 직업 랭킹 :{" "}
                    {mainData.characterInfo.classRanking.ranking[0].ranking} 위
                  </div>
                </>
              ) : (
                <div>직업 랭킹 정보가 없습니다.</div>
              )}
            </>
          </div>
          <div className={styles.dojangRankingBox}>
            {!!mainData.characterInfo.dojang && (
              <>
                <div className={styles.eachImgbox}>
                  <img
                    src={require(`./img/dojang/${mainData.characterInfo.dojang.dojang_best_floor}.png`)}
                    alt="도장 이미지"
                  />
                </div>
                <div className={styles.line}></div>
                <div>{mainData.characterInfo.dojang.dojang_best_floor} 층</div>
                <div>
                  달성 일 :{" "}
                  {!!mainData.characterInfo.dojang.date_dojang_record &&
                    mainData.characterInfo.dojang.date_dojang_record.split(
                      "T"
                    )[0]}
                </div>
                {mainData.characterInfo.dojangRanking.ranking.length !== 0 && (
                  <div> 랭킹에 도장 정보 있으면 도장 정보 출력</div>
                )}
              </>
            )}
          </div>
          <div className={styles.achievementRankingBox}>
            {mainData.characterInfo.achievementRanking.ranking.length !== 0 && (
              <>
                <div className={styles.eachImgbox}>
                  <img
                    src={require(`./img/achievement/${mainData.characterInfo.achievementRanking.ranking[0].trophy_grade}.webp`)}
                  ></img>
                </div>
                <div className={styles.line}></div>
                <div>
                  {
                    mainData.characterInfo.achievementRanking.ranking[0]
                      .trophy_grade
                  }
                </div>
                <div>
                  {
                    mainData.characterInfo.achievementRanking.ranking[0]
                      .trophy_score
                  }{" "}
                  점
                </div>
                <div>
                  {mainData.characterInfo.achievementRanking.ranking[0].ranking}{" "}
                  위
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.unionTetrisContainer}>
          <div className={styles.unionTetrisTitle}>
            <div>유니온 점령 효과 및 배치도</div>
          </div>
          <div className={styles.unionTetrisBox}>
            {isMobile ? (
              <div className={styles.unionTetrisImgBox}>
                <div
                  className={styles.unionTetris}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(22, 15px)",
                  }}
                >
                  {/* 바둑판 생성 */}
                  {Array.from({ length: 20 }, (_, y) =>
                    Array.from({ length: 22 }, (_, x) => {
                      const posX = x - 11;
                      const posY = -y + 9;
                      const isInRange =
                        posX >= -6 && posX <= 5 && posY >= -5 && posY <= 4;

                      return (
                        <div
                          key={`${posX}-${posY}`}
                          style={{
                            width: "15px",
                            height: "15px",
                            border: `1px solid ${isInRange ? "green" : "#000"}`,
                            backgroundColor:
                              boardState[`${posX}-${posY}`] || "white",
                          }}
                        />
                      );
                    })
                  )}
                </div>
                {isMobile ? (
                  <>
                    <div className={styles.firstText}>크뎀</div>
                    <div className={styles.secondText}>방무</div>
                    <div className={styles.thirdText}>크확</div>
                    <div className={styles.fourthText}>보뎀</div>
                    <div className={styles.fifthText}>내성</div>
                    <div className={styles.sixthText}>경험치</div>
                    <div className={styles.seventhText}>버프지속</div>
                    <div className={styles.eighthText}>일몹뎀</div>
                  </>
                ) : (
                  <>
                    <div className={styles.firstText}>크리티컬 데미지</div>
                    <div className={styles.secondText}>방어율 무시</div>
                    <div className={styles.thirdText}>크리티컬 확률</div>
                    <div className={styles.fourthText}>보스 데미지</div>
                    <div className={styles.fifthText}>상태이상내성</div>
                    <div className={styles.sixthText}>획득 경험치</div>
                    <div className={styles.seventhText}>버프지속시간</div>
                    <div className={styles.eighthText}>일반 데미지</div>
                  </>
                )}
              </div>
            ) : (
              <div className={styles.unionTetrisImgBox}>
                <div
                  className={styles.unionTetris}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(22, 20px)",
                  }}
                >
                  {/* 바둑판 생성 */}
                  {Array.from({ length: 20 }, (_, y) =>
                    Array.from({ length: 22 }, (_, x) => {
                      const posX = x - 11;
                      const posY = -y + 9;
                      const isInRange =
                        posX >= -6 && posX <= 5 && posY >= -5 && posY <= 4;

                      return (
                        <div
                          key={`${posX}-${posY}`}
                          style={{
                            width: "20px",
                            height: "20px",
                            border: `1px solid ${isInRange ? "green" : "#000"}`,
                            backgroundColor:
                              boardState[`${posX}-${posY}`] || "white",
                          }}
                        />
                      );
                    })
                  )}
                </div>
                <div className={styles.firstText}>크리티컬 데미지</div>
                <div className={styles.secondText}>방어율 무시</div>
                <div className={styles.thirdText}>크리티컬 확률</div>
                <div className={styles.fourthText}>보스 데미지</div>
                <div className={styles.fifthText}>상태이상내성</div>
                <div className={styles.sixthText}>획득 경험치</div>
                <div className={styles.seventhText}>버프지속시간</div>
                <div className={styles.eighthText}>일반 데미지</div>
              </div>
            )}

            <div className={styles.unionTetrisTextBox}>
              <div className={styles.unionOccupiedStat}>
                {unionRaider &&
                  unionRaider.union_occupied_stat.length !== 0 && (
                    <>
                      {unionRaider.union_occupied_stat.map((item, index) => (
                        <div>{item}</div>
                      ))}
                    </>
                  )}
              </div>
              <div className={styles.unionRaiderStat}>
                {unionRaider && unionRaider.union_raider_stat.length !== 0 && (
                  <>
                    {unionRaider.union_raider_stat.map((item, index) => (
                      <div>{item}</div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.unionArtifactContainer}>
          <div className={styles.unionArtifactTitle}>
            <div>유니온 아티팩트</div>
          </div>
          <div className={styles.unionArtifactbox}>
            <div className={styles.unionArtifactImgBox}>
              <div className={styles.unionArtifactEachImgBox}>
                <div className={styles.unionArtifactCrystalLevel}>
                  {unionArtifact &&
                  unionArtifact.union_artifact_crystal &&
                  unionArtifact.union_artifact_crystal.length > 0 &&
                  unionArtifact.union_artifact_crystal[0] !== null &&
                  unionArtifact.union_artifact_crystal[0] !== undefined ? (
                    <div>
                      Lv.{unionArtifact.union_artifact_crystal[0].level}
                    </div>
                  ) : null}
                </div>
                <div className={styles.unionArtifactCrystalImg}>
                  {unionArtifact &&
                  unionArtifact.union_artifact_crystal &&
                  unionArtifact.union_artifact_crystal.length > 0 &&
                  unionArtifact.union_artifact_crystal[0] !== null &&
                  unionArtifact.union_artifact_crystal[0] !== undefined ? (
                    <div onClick={handleArtifactClick0}>
                      {unionArtifact.union_artifact_crystal[0].level !== 5 ? (
                        <img
                          src={require("./img/artifact/주황버섯.webp")}
                          alt="artifact"
                        ></img>
                      ) : (
                        <img
                          src={require("./img/artifact/주황버섯2.webp")}
                          alt="artifact"
                        ></img>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={styles.unionArtifactEachImgBox}>
                <div className={styles.unionArtifactCrystalLevel}>
                  {unionArtifact &&
                  unionArtifact.union_artifact_crystal &&
                  unionArtifact.union_artifact_crystal.length > 0 &&
                  unionArtifact.union_artifact_crystal[1] !== null &&
                  unionArtifact.union_artifact_crystal[1] !== undefined ? (
                    <div>
                      Lv.{unionArtifact.union_artifact_crystal[1].level}
                    </div>
                  ) : null}
                </div>
                <div className={styles.unionArtifactCrystalImg}>
                  {unionArtifact &&
                  unionArtifact.union_artifact_crystal &&
                  unionArtifact.union_artifact_crystal.length > 0 &&
                  unionArtifact.union_artifact_crystal[1] !== null &&
                  unionArtifact.union_artifact_crystal[1] !== undefined ? (
                    <div onClick={handleArtifactClick1}>
                      {unionArtifact.union_artifact_crystal[1].level !== 5 ? (
                        <img
                          src={require("./img/artifact/슬라임.webp")}
                          alt="artifact"
                        ></img>
                      ) : (
                        <img
                          src={require("./img/artifact/슬라임2.webp")}
                          alt="artifact"
                        ></img>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={styles.unionArtifactEachImgBox}>
                <div className={styles.unionArtifactCrystalLevel}>
                  {unionArtifact &&
                  unionArtifact.union_artifact_crystal &&
                  unionArtifact.union_artifact_crystal.length > 0 &&
                  unionArtifact.union_artifact_crystal[2] !== null &&
                  unionArtifact.union_artifact_crystal[2] !== undefined ? (
                    <div>
                      Lv.{unionArtifact.union_artifact_crystal[2].level}
                    </div>
                  ) : null}
                </div>
                <div className={styles.unionArtifactCrystalImg}>
                  {unionArtifact &&
                  unionArtifact.union_artifact_crystal &&
                  unionArtifact.union_artifact_crystal.length > 0 &&
                  unionArtifact.union_artifact_crystal[2] !== null &&
                  unionArtifact.union_artifact_crystal[2] !== undefined ? (
                    <div onClick={handleArtifactClick2}>
                      {unionArtifact.union_artifact_crystal[2].level !== 5 ? (
                        <img
                          src={require("./img/artifact/뿔버섯.webp")}
                          alt="artifact"
                        ></img>
                      ) : (
                        <img
                          src={require("./img/artifact/뿔버섯2.webp")}
                          alt="artifact"
                        ></img>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={styles.unionArtifactEachImgBox}>
                <div className={styles.unionArtifactCrystalLevel}>
                  {unionArtifact &&
                  unionArtifact.union_artifact_crystal &&
                  unionArtifact.union_artifact_crystal.length > 0 &&
                  unionArtifact.union_artifact_crystal[3] !== null &&
                  unionArtifact.union_artifact_crystal[3] !== undefined ? (
                    <div>
                      Lv.{unionArtifact.union_artifact_crystal[3].level}
                    </div>
                  ) : null}
                </div>
                <div className={styles.unionArtifactCrystalImg}>
                  {unionArtifact &&
                  unionArtifact.union_artifact_crystal &&
                  unionArtifact.union_artifact_crystal.length > 0 &&
                  unionArtifact.union_artifact_crystal[3] !== null &&
                  unionArtifact.union_artifact_crystal[3] !== undefined ? (
                    <div onClick={handleArtifactClick3}>
                      {unionArtifact.union_artifact_crystal[3].level !== 5 ? (
                        <img
                          src={require("./img/artifact/스텀프.webp")}
                          alt="artifact"
                        ></img>
                      ) : (
                        <img
                          src={require("./img/artifact/스텀프2.webp")}
                          alt="artifact"
                        ></img>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={styles.unionArtifactEachImgBox}>
                <div className={styles.unionArtifactCrystalLevel}>
                  {unionArtifact &&
                  unionArtifact.union_artifact_crystal &&
                  unionArtifact.union_artifact_crystal.length > 0 &&
                  unionArtifact.union_artifact_crystal[4] !== null &&
                  unionArtifact.union_artifact_crystal[4] !== undefined ? (
                    <div>
                      Lv.{unionArtifact.union_artifact_crystal[4].level}
                    </div>
                  ) : null}
                </div>
                <div className={styles.unionArtifactCrystalImg}>
                  {unionArtifact &&
                  unionArtifact.union_artifact_crystal &&
                  unionArtifact.union_artifact_crystal.length > 0 &&
                  unionArtifact.union_artifact_crystal[4] !== null &&
                  unionArtifact.union_artifact_crystal[4] !== undefined ? (
                    <div onClick={handleArtifactClick4}>
                      {unionArtifact.union_artifact_crystal[4].level !== 5 ? (
                        <img
                          src={require("./img/artifact/스톤골렘.webp")}
                          alt="artifact"
                        ></img>
                      ) : (
                        <img
                          src={require("./img/artifact/스톤골렘.webp")}
                          alt="artifact"
                        ></img>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={styles.unionArtifactEachImgBox}>
                <div className={styles.unionArtifactCrystalLevel}>
                  {unionArtifact &&
                  unionArtifact.union_artifact_crystal &&
                  unionArtifact.union_artifact_crystal.length > 0 &&
                  unionArtifact.union_artifact_crystal[5] !== null &&
                  unionArtifact.union_artifact_crystal[5] !== undefined ? (
                    <div>
                      Lv.{unionArtifact.union_artifact_crystal[5].level}
                    </div>
                  ) : null}
                </div>
                <div className={styles.unionArtifactCrystalImg}>
                  {unionArtifact &&
                  unionArtifact.union_artifact_crystal &&
                  unionArtifact.union_artifact_crystal.length > 0 &&
                  unionArtifact.union_artifact_crystal[5] !== null &&
                  unionArtifact.union_artifact_crystal[5] !== undefined ? (
                    <div onClick={handleArtifactClick5}>
                      {unionArtifact.union_artifact_crystal[5].level !== 5 ? (
                        <img
                          src={require("./img/artifact/발록.webp")}
                          alt="artifact"
                        ></img>
                      ) : (
                        <img
                          src={require("./img/artifact/발록2.webp")}
                          alt="artifact"
                        ></img>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={styles.unionArtifactEachImgBox}>
                <div>
                  <div className={styles.unionArtifactCrystalLevel}>
                    {unionArtifact &&
                    unionArtifact.union_artifact_crystal &&
                    unionArtifact.union_artifact_crystal.length > 0 &&
                    unionArtifact.union_artifact_crystal[6] !== null &&
                    unionArtifact.union_artifact_crystal[6] !== undefined ? (
                      <div>
                        Lv.{unionArtifact.union_artifact_crystal[6].level}
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.unionArtifactCrystalImg}>
                    {unionArtifact &&
                    unionArtifact.union_artifact_crystal &&
                    unionArtifact.union_artifact_crystal.length > 0 &&
                    unionArtifact.union_artifact_crystal[6] !== null &&
                    unionArtifact.union_artifact_crystal[6] !== undefined ? (
                      <div onClick={handleArtifactClick6}>
                        {unionArtifact.union_artifact_crystal[6].level !== 5 ? (
                          <img
                            src={require("./img/artifact/자쿰.webp")}
                            alt="artifact"
                          ></img>
                        ) : (
                          <img
                            src={require("./img/artifact/자쿰.webp")}
                            alt="artifact"
                          ></img>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={styles.unionArtifactEachImgBox}>
                <div className={styles.unionArtifactCrystalLevel}>
                  {unionArtifact &&
                  unionArtifact.union_artifact_crystal &&
                  unionArtifact.union_artifact_crystal.length > 0 &&
                  unionArtifact.union_artifact_crystal[7] !== null &&
                  unionArtifact.union_artifact_crystal[7] !== undefined ? (
                    <div>
                      Lv.{unionArtifact.union_artifact_crystal[7].level}
                    </div>
                  ) : null}
                </div>
                <div className={styles.unionArtifactCrystalImg}>
                  {unionArtifact &&
                  unionArtifact.union_artifact_crystal &&
                  unionArtifact.union_artifact_crystal.length > 0 &&
                  unionArtifact.union_artifact_crystal[7] !== null &&
                  unionArtifact.union_artifact_crystal[7] !== undefined ? (
                    <div onClick={handleArtifactClick7}>
                      {unionArtifact.union_artifact_crystal[7].level !== 5 ? (
                        <img
                          src={require("./img/artifact/핑크빈.webp")}
                          alt="artifact"
                        ></img>
                      ) : (
                        <img
                          src={require("./img/artifact/핑크빈.webp")}
                          alt="artifact"
                        ></img>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={styles.unionArtifactEachImgBox}>
                <div className={styles.unionArtifactCrystalLevel}>
                  {unionArtifact &&
                  unionArtifact.union_artifact_crystal &&
                  unionArtifact.union_artifact_crystal.length > 0 &&
                  unionArtifact.union_artifact_crystal[8] !== null &&
                  unionArtifact.union_artifact_crystal[8] !== undefined ? (
                    <div>
                      Lv.{unionArtifact.union_artifact_crystal[8].level}
                    </div>
                  ) : null}
                </div>
                <div className={styles.unionArtifactCrystalImg}>
                  {unionArtifact &&
                  unionArtifact.union_artifact_crystal &&
                  unionArtifact.union_artifact_crystal.length > 0 &&
                  unionArtifact.union_artifact_crystal[8] !== null &&
                  unionArtifact.union_artifact_crystal[8] !== undefined ? (
                    <div onClick={handleArtifactClick8}>
                      {unionArtifact.union_artifact_crystal[8].level !== 5 ? (
                        <img
                          src={require("./img/artifact/파풀라투스.webp")}
                          alt="artifact"
                        ></img>
                      ) : (
                        <img
                          src={require("./img/artifact/파풀라투스.webp")}
                          alt="artifact"
                        ></img>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={styles.unionArtifactTextBox}>
              <div className={styles.unionArtifactDetailBox}>
                <div className={styles.unionArtifactEffectTitle}>
                  <div>아티팩트 능력치</div>
                </div>
                {selectedArtifactInfo ? (
                  <div className={styles.unionArtifactOptions}>
                    <div>{selectedArtifactInfo.crystal_option_name_1}</div>
                    <div>{selectedArtifactInfo.crystal_option_name_2}</div>
                    <div>{selectedArtifactInfo.crystal_option_name_3}</div>
                  </div>
                ) : (
                  <div className={styles.unionArtifactOptions}>
                    아티팩트를 선택해 보세요!
                  </div>
                )}
              </div>
              <div className={styles.unionArtifactEffectBox}>
                <div>
                  <div className={styles.unionArtifactEffectBoxInner}>
                    <div className={styles.unionArtifactEffectTitle}>
                      <div>아티팩트 이팩트</div>
                    </div>
                    <div className={styles.unionArtifactEffectDetailBox}>
                      {unionArtifact &&
                        unionArtifact.union_artifact_crystal &&
                        unionArtifact.union_artifact_crystal.length > 0 &&
                        unionArtifact.union_artifact_effect.length > 0 &&
                        unionArtifact.union_artifact_effect.map(
                          (item, index) => (
                            <div className={styles.unionArtifactOptions2}>
                              Lv.{item.level} | {item.name}
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RankingAndUnion;
