import React, { useEffect } from "react";
import styles from "./Skill.module.css";
import piece from "./img/조각.webp";
import sol from "./img/솔.webp";
import { useState } from "react";
import ChracterInfo from "./CharacterInfo";

function Skill(props) {
  const mainData = props.mainData;

  /* 아이템 선택시 */
  const [selectedItem, setSelectedItem] = useState(null);
  const handleItemClick = (item) => {
    setSelectedItem(item);
    /* 아이템 선택시 */
  };

  // 마스터리코어
  const masteryPiece = [
    50, 15, 18, 20, 23, 25, 28, 30, 33, 100, 40, 45, 50, 55, 60, 65, 70, 75, 80,
    175, 85, 90, 95, 100, 105, 110, 115, 120, 125, 250,
  ];

  const masterySol = [
    3, 1, 1, 1, 1, 1, 1, 2, 2, 5, 2, 2, 2, 2, 2, 2, 2, 2, 3, 8, 3, 3, 3, 3, 3,
    3, 3, 3, 4, 10,
  ];
  const masteryNum = (level) => {
    let sumPiece = 0;
    let sumSol = 0;

    for (let i = 0; i < level; i++) {
      sumPiece = sumPiece + masteryPiece[i];
      sumSol = sumSol + masterySol[i];
    }

    return [sumPiece, sumSol];
  };

  //스킬코어
  const skillPiece = [
    100, 30, 35, 40, 45, 50, 55, 60, 65, 200, 80, 90, 100, 110, 120, 130, 140,
    150, 160, 350, 170, 180, 190, 200, 210, 220, 230, 240, 250, 500,
  ];

  const skillSol = [
    5, 1, 1, 1, 2, 2, 2, 3, 3, 10, 3, 3, 4, 4, 4, 4, 4, 4, 5, 15, 5, 5, 5, 5, 5,
    6, 6, 6, 7, 20,
  ];

  const skillNum = (level) => {
    let sumPiece = 0;
    let sumSol = 0;

    for (let i = 0; i < level; i++) {
      sumPiece = sumPiece + skillPiece[i];
      sumSol = sumSol + skillSol[i];
    }

    return [sumPiece, sumSol];
  };

  //강화코어
  const enhancedPiece = [
    75, 23, 27, 30, 34, 38, 42, 45, 49, 150, 60, 68, 75, 83, 90, 98, 105, 113,
    120, 263, 128, 135, 143, 150, 158, 165, 173, 180, 188, 375,
  ];
  const enhancedSol = [
    4, 1, 1, 1, 2, 2, 2, 3, 3, 8, 3, 3, 3, 3, 3, 3, 3, 3, 4, 12, 4, 4, 4, 4, 4,
    5, 5, 5, 6, 15,
  ];
  const enhancedNum = (level) => {
    let sumPiece = 0;
    let sumSol = 0;

    for (let i = 0; i < level; i++) {
      sumPiece = sumPiece + enhancedPiece[i];
      sumSol = sumSol + enhancedSol[i];
    }

    return [sumPiece, sumSol];
  };

  //공용코어
  const publicPiece = [
    125, 38, 44, 50, 57, 63, 69, 75, 82, 300, 110, 124, 138, 152, 165, 179, 193,
    207, 220, 525, 234, 248, 262, 275, 289, 303, 317, 330, 344, 750,
  ];
  const publicSol = [
    7, 2, 2, 2, 3, 3, 3, 5, 5, 14, 5, 5, 6, 6, 6, 6, 6, 6, 7, 17, 7, 7, 7, 7, 7,
    9, 9, 9, 10, 20,
  ];

  const publicNum = (level) => {
    let sumPiece = 0;
    let sumSol = 0;

    for (let i = 0; i < level; i++) {
      sumPiece = sumPiece + publicPiece[i];
      sumSol = sumSol + publicSol[i];
    }

    return [sumPiece, sumSol];
  };

  const skill_length = mainData.characterInfo.skill.character_skill.length;
  const skill = mainData.characterInfo.skill.character_skill;

  const controller = (string, level) => {
    if (string === "강화 코어") {
      return enhancedNum(level)[0];
    } else if (string === "스킬 코어") {
      return skillNum(level)[0];
    } else if (string === "공용 코어") {
      return publicNum(level)[0];
    } else if (string === "마스터리 코어") {
      return masteryNum(level)[0];
    }
  };

  const controller2 = (string, level) => {
    if (string === "강화 코어") {
      return enhancedNum(level)[1];
    } else if (string === "스킬 코어") {
      return skillNum(level)[1];
    } else if (string === "공용 코어") {
      return publicNum(level)[1];
    } else if (string === "마스터리 코어") {
      return masteryNum(level)[1];
    }
  };

  const hexaData =
    mainData.characterInfo.hexamatrix.character_hexa_core_equipment;

  const pieceNumCal = () => {
    let sum = 0;

    for (let j = 0; j < hexaData.length; j++) {
      if (hexaData[j].hexa_core_type === "강화 코어") {
        sum = sum + enhancedNum(hexaData[j].hexa_core_level)[0];
      } else if (hexaData[j].hexa_core_type === "스킬 코어") {
        sum = sum + skillNum(hexaData[j].hexa_core_level)[0];
      } else if (hexaData[j].hexa_core_type === "공용 코어") {
        sum = sum + publicNum(hexaData[j].hexa_core_level)[0];
      } else if (hexaData[j].hexa_core_type === "마스터리 코어") {
        sum = sum + masteryNum(hexaData[j].hexa_core_level)[0];
      }
    }
    return sum;
  };

  const solNumCal = () => {
    let sum = 0;

    for (let j = 0; j < hexaData.length; j++) {
      if (hexaData[j].hexa_core_type === "강화 코어") {
        sum = sum + enhancedNum(hexaData[j].hexa_core_level)[1];
      } else if (hexaData[j].hexa_core_type === "스킬 코어") {
        sum = sum + skillNum(hexaData[j].hexa_core_level)[1];
      } else if (hexaData[j].hexa_core_type === "공용 코어") {
        sum = sum + publicNum(hexaData[j].hexa_core_level)[1];
      } else if (hexaData[j].hexa_core_type === "마스터리 코어") {
        sum = sum + masteryNum(hexaData[j].hexa_core_level)[1];
      }
    }
    return sum;
  };

  function truncateText(text, maxLength) {
    if (text === null) {
      return "";
    }

    // 텍스트 길이 체크
    if (text.length > maxLength) {
      text = text.substring(0, maxLength) + "...";
    }
    return text;
  }

  const vmatrix = mainData.characterInfo.vmatrix.character_v_core_equipment;

  console.log(mainData.characterInfo);
  return (
    <div className={styles.mainBoxContainer}>
      <div className={styles.mainBox}>
        <ChracterInfo mainData={mainData}></ChracterInfo>
        {mainData.characterInfo.skill.character_skill.length !== 0 ? (
          <div className={styles.pieceAndSolContainer}>
            <div className={styles.hexaTitle}>
              <div>HEXA matrix</div>
            </div>
            <div className={styles.pieceAndSolBoxBox}>
              <div className={styles.pieceAndSolBox}>
                <div>
                  <img src={piece} alt="조각 이미지" />
                </div>
                <div>
                  <div>사용한 조각</div>
                  <div>{pieceNumCal()} 개</div>
                </div>
              </div>
              <div className={styles.pieceAndSolBox}>
                <div>
                  <img src={sol} alt="솔 이미지" />
                </div>
                <div>
                  <div>사용한 솔 에르다</div>
                  <div>{solNumCal()} 개</div>
                </div>
              </div>
            </div>
            <div className={styles.skillContainer}>
              <div className={styles.skillsBox}>
                {mainData.characterInfo.skill.character_skill.length !== 0 ? (
                  <>
                    {mainData.characterInfo.skill.character_skill.map(
                      (item, index) => (
                        <div
                          key={index}
                          className={styles.skillImgAndNameBox}
                          onClick={() => handleItemClick(item)}
                        >
                          <img
                            src={item.skill_icon}
                            alt={`${index} skill img`}
                          ></img>
                          {/* {allPieceCal(item)} */}
                          <div className={styles.skillLevelBox}>
                            {item.skill_level}
                          </div>
                          {/* <div>{item.skill_name}</div> */}
                        </div>
                      )
                    )}
                  </>
                ) : (
                  <div>6차 스킬 없는경우</div>
                )}
              </div>
              <div className={styles.skillTextBox}>
                {selectedItem ? (
                  <div className={styles.skillText}>
                    <div>{selectedItem.skill_name}</div>
                    <div>
                      <img src={selectedItem.skill_icon} alt="선택스킬이미지" />
                    </div>
                    <div>
                      {/* {selectedItem.skill_description} */}
                      {truncateText(selectedItem.skill_description, 80)}
                    </div>
                    <div>
                      {/* {selectedItem.skill_effect} */}
                      {truncateText(selectedItem.skill_effect, 80)}
                    </div>
                    {hexaData.map((item) => {
                      if (
                        item.hexa_core_name.includes(selectedItem.skill_name)
                      ) {
                        return (
                          <div className={styles.skillDetail}>
                            <div className={styles.skillDetailPiece}>
                              <div className={styles.solImgBox}>
                                <img
                                  src={piece}
                                  alt="조각이미지"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                              </div>
                              <div>
                                {controller(
                                  item.hexa_core_type,
                                  selectedItem.skill_level
                                )}{" "}
                              </div>
                            </div>
                            <div className={styles.skillDetailPiece}>
                              <div className={styles.solImgBox}>
                                <img
                                  src={sol}
                                  alt="솔이미지"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                              </div>
                              <div>
                                {controller2(
                                  item.hexa_core_type,
                                  selectedItem.skill_level
                                )}{" "}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}

                    {selectedItem.skill_name === "HEXA 스탯" && (
                      <>
                        <div>
                          메인 스탯 Lv.
                          {
                            mainData.characterInfo.hexamatrixStat
                              .character_hexa_stat_core[0].main_stat_level
                          }{" "}
                          /{" "}
                          {
                            mainData.characterInfo.hexamatrixStat
                              .character_hexa_stat_core[0].main_stat_name
                          }
                        </div>
                        <div>
                          서브 스탯 Lv.
                          {
                            mainData.characterInfo.hexamatrixStat
                              .character_hexa_stat_core[0].sub_stat_level_1
                          }{" "}
                          /{" "}
                          {
                            mainData.characterInfo.hexamatrixStat
                              .character_hexa_stat_core[0].sub_stat_name_1
                          }
                        </div>
                        <div>
                          서브 스탯 Lv.
                          {
                            mainData.characterInfo.hexamatrixStat
                              .character_hexa_stat_core[0].sub_stat_level_2
                          }{" "}
                          /{" "}
                          {
                            mainData.characterInfo.hexamatrixStat
                              .character_hexa_stat_core[0].sub_stat_name_2
                          }
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div>스킬을 클릭하여 상세 정보를 확인해보세요.</div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}

        <div className={styles.hyperContainer}>
          <div className={styles.hyperTitle}>
            <div>V matrix</div>
          </div>
          {vmatrix.length !== 0 && (
            <div className={styles.hyperBox}>
              {vmatrix.map((item, index) => {
                return (
                  <div className={styles.hyperEach}>
                    Lv.{item.v_core_level} {item.v_core_name}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Skill;

// const masteryCore = ['레이지 업라이징 VI', '디바인 차지 VI', '디바인 스티그마 VI', '폴링 저스티스','다크 임페일 VI', '다크 신서시스 VI', '플레임 헤이즈 VI', '미스트 이럽션 VI', '프로즌 오브 VI', '블리자드 VI',  ]
// const masteryCore = [
//   "레이징 블로우 VI",
//   "블래스트 VI",
//   "궁니르 디센트 VI",
//   "플레임 스윕 VI",
//   "체인 라이트닝 VI",
//   "엔젤레이 VI",
//   "폭풍의 시 VI",
//   "스나이핑 VI",
//   "카디널 블래스트 VI",
//   "쿼드러플 스로우 VI",
//   "암살 VI",
//   "팬텀 블로우 VI",
//   "피스트 인레이지 VI",
//   "래피드 파이어 VI",
//   "캐논 버스터 VI",
//   "샤이닝 크로스 VI",
//   "솔라 슬래시 VI",
//   "오비탈 플레임 VI",
//   "천공의 노래 VI",
//   "퀸터플 스로우 VI",
//   "섬멸 VI",
//   "비욘더 VI",
//   "서클 오브 마나 VI",
//   "앱솔루트 킬 VI",
//   "이슈타르의 링 VI",
//   "템페스트 오브 카드 VI",
//   "귀참 VI",
//   "매그넘 펀치 VI",
//   "데스 VI",
//   "와일드 발칸 VI",
//   "퍼지롭 매스커레이드 VI : 저격",
//   "매시브 파이어 : SPLASH-F VI",
//   "데몬 임팩트 VI",
//   "실드 체이싱 VI",
//   "기가 슬래셔 VI",
//   "폴링 더스트 VI",
//   "체인아츠:스트로크 VI",
//   "트리니티 VI",
//   "기가 크래시 VI",
//   "얼티메이트-메테리얼 VI",
//   "디바이드 VI",
//   "크래프트:자벨린 VI",
//   "글로리 윙:자벨린 VI",
//   "아츠 : 플러리 VI",
//   "플레인 차지드라이브 VI",
//   "정기 뿌리기 VI",
//   "멸화염 : 천 VI",
// ];

// const skillCore = [
//   "스피릿 칼리버",
//   "세이크리드 바스티온",
//   "데드 스페이스",
//   "인페르날 베놈",
//   "프로즌 라이트닝",
//   "홀리 어드밴트",
//   "어센던트 셰이드",
//   "파이널 에임",
//   "포세이큰 렐릭",
//   "생사여탈",
//   "일도양단",
//   "카르마 블레이드",
//   "리버레이트 넵투누스",
//   "드레드노트",
//   "슈퍼 캐논 익스플로젼",
//   "듀란달",
//   "아스트랄 블리츠",
//   "이터너티",
//   "미스트랄 스프링",
//   "사일런스",
//   "뇌명벽해파",
//   "아드레날린 서지",
//   "조디악 버스트",
//   "하모닉 패러독스",
//   "언페이딩 글로리",
//   "디파잉 페이트",
//   "호신 강림",
//   "파이널 디스트로이어",
//   "크림슨 팩텀",
//   "네이쳐스 빌리프",
//   "아티피셜 에볼루션",
//   "그라운드 제로",
//   "나이트메어",
//   "레퀴엠",
//   "마이트 오브 노바",
//   "어나일레이션",
//   "체인아츠:매서커",
//   "그랜드 피날레",
//   "크로노 트리거",
//   "아나더 렐름",
//   "마에스트로",
//   "언리미티드 크리스탈",
//   "헥스 : 샌드스톰",
//   "가장 오래된 심연",
//   "새록새록 꽃누리",
//   "선기 : 파천황",
// ];

// const enhancedCore = [
//   "소드 오브 버닝 소울 강화",
//   "콤보 인스팅트 강화",
//   "콤보 데스폴트 강화",
//   "소드 일루전 강화",
//   "홀리 유니티 강화",
//   "블래스드 해머 강화",
//   "그랜드 크로스 강화",
//   "마이티 묠니르 강화",
//   "다크 스피어 강화",
//   "비홀더 임팩트 강화",
//   "피어스 사이클론 강화",
//   "다크니스 오라 강화",
//   "도트 퍼니셔 강화",
//   "포이즌 노바 강화",
//   "퓨리 오브 이프리트 강화",
//   "포이즌 체인 강화",
//   "아이스 에이지 강화",
//   "썬더 브레이크 강화",
//   "스피릿 오브 스노우 강화",
//   "주피터 썬더 강화",
//   "프레이 강화",
//   "엔젤 오브 리브라 강화",
//   "피스메이커 강화",
//   "디바인 퍼니시먼트 강화",
//   "애로우 레인 강화",
//   "잔영의 시 강화",
//   "퀴버 풀버스트 강화",
//   "실루엣 미라주 강화",
//   "트루 스나이핑 강화",
//   "스플릿 애로우 강화",
//   "차지드 애로우 강화",
//   "리피팅 크로스보우 카트리지 강화",
//   "얼티밋 블래스트 강화",
//   "레이븐 템페스트/이볼브 템페스트 강화",
//   "옵시디언 배리어 강화",
//   "렐릭 언바운드 강화",
//   "스프레드 스로우 강화",
//   "풍마수리검 강화",
//   "다크로드의 비전서 강화",
//   "스로우 블래스팅 강화",
//   "쉐도우 어썰트 강화",
//   "절개 강화",
//   "소닉 블로우 강화",
//   "멸귀참영진 강화",
//   "블레이드 스톰 강화",
//   "카르마 퓨리 강화",
//   "블레이드 토네이도 강화",
//   "헌티드 엣지 강화",
//   "라이트닝 폼 강화",
//   "서펜트 스크류 강화",
//   "퓨리어스 차지 강화",
//   "하울링 피스트 강화",
//   "불릿 파티 강화",
//   "데드아이 강화",
//   "노틸러스 어썰트 강화",
//   "데스 트리거 강화",
//   "빅 휴즈 기간틱 캐논볼 강화",
//   "ICBM 강화",
//   "스페셜 몽키 에스코트 강화",
//   "풀 메이커 강화",
//   "로 아이아스 강화",
//   "클라우 솔라스 강화",
//   "소드 오브 소울 라이트 강화",
//   "라이트 오브 커리지 강화",
//   "코스모스 강화",
//   "엘리시온 강화",
//   "소울 이클립스 강화",
//   "플레어 슬래시 강화",
//   "블레이징 오비탈 플레임 강화",
//   "플레임 디스차지 강화",
//   "인피니티 플레임 서클 강화",
//   "샐리맨더 미스칩 강화",
//   "하울링 게일 강화",
//   "아이들 윔 강화",
//   "윈드 월 강화",
//   "볼텍스 스피어 강화",
//   "쉐도우 스피어 강화",
//   "쉐도우 서번트 익스텐드 강화",
//   "쉐도우 바이트 강화",
//   "래피드 스로우 강화",
//   "신뇌합일 강화",
//   "교아탄 강화",
//   "뇌신창격 강화",
//   "창뇌연격 강화",
//   "인스톨 마하 강화",
//   "브랜디쉬 마하 강화",
//   "펜릴 크래시 강화",
//   "블리자드 템페스트 강화",
//   "엘리멘탈 블래스트 강화",
//   "드래곤 브레이크 강화",
//   "조디악 레이 강화",
//   "스파이럴 오브 마나 강화",
//   "진리의 문 강화",
//   "퍼니싱 리소네이터 강화",
//   "빛과 어둠의 세례 강화",
//   "리버레이션 오브 강화",
//   "엘리멘탈 고스트 강화",
//   "실피디아 강화",
//   "이르칼라의 숨결 강화",
//   "로얄 나이츠 강화",
//   "조커 강화",
//   "블랙잭 강화",
//   "마크 오브 팬텀 강화",
//   "리프트 브레이크 강화",
//   "정령 집속 강화",
//   "귀문진 강화",
//   "진 귀참 강화",
//   "파쇄연권 강화",
//   "벙커 버스터 강화",
//   "발칸 펀치 강화",
//   "버닝 브레이커 강화",
//   "애프터이미지 쇼크 강화",
//   "유니온 오라 강화",
//   "블랙 매직 알터 강화",
//   "그림 리퍼 강화",
//   "어비셜 라이트닝 강화",
//   "재규어 스톰 강화",
//   "재규어 맥시멈 강화",
//   "와일드 그레네이드 강화",
//   "와일드 발칸 Type X 강화",
//   "메가 스매셔 강화",
//   "오버로드 모드 강화",
//   "홀로그램 그래피티 : 융합 강화",
//   "포톤 레이 강화",
//   "멀티플 옵션 : M-FL 강화",
//   "마이크로 미사일 컨테이너 강화",
//   "메탈아머 전탄발사 강화",
//   "메카 캐리어 강화",
//   "데몬 어웨이크닝 강화",
//   "요르문간드 강화",
//   "오르트로스 강화",
//   "데몬 베인 강화",
//   "데몬 프렌지 강화",
//   "블러드 피스트 강화",
//   "디멘션 소드 강화",
//   "레버넌트 강화",
//   "가디언 오브 노바 강화",
//   "윌 오브 소드 : 스트라이크 강화",
//   "드라코 슬래셔 강화",
//   "드래곤 블레이즈 강화",
//   "드래곤 버스트 강화",
//   "페이탈 블리츠 강화",
//   "타나토스 디센트 강화",
//   "그립 오브 애거니 강화",
//   "체인아츠:퓨리 강화",
//   "A.D 오드넌스 강화",
//   "체인아츠:메일스트롬 강화",
//   "웨폰 버라이어티 피날레 강화",
//   "에너지 버스트 강화",
//   "스포트라이트 강화",
//   "마스코트 패밀리어 강화",
//   "트리니티 퓨전 강화",
//   "리미트 브레이크 강화",
//   "조인트 어택 강화",
//   "쉐도우 플래시 강화",
//   "에고 웨폰 강화",
//   "싸이킥 토네이도 강화",
//   "얼티메이트-무빙 매터 강화",
//   "얼티메이트-싸이킥 불릿 강화",
//   "로 오브 그라비티 강화",
//   "루인 강화",
//   "인피니트 강화",
//   "리스토어 강화",
//   "스톰 강화",
//   "크리스탈 이그니션 강화",
//   "그람홀더 강화",
//   "소울 오브 크리스탈 강화",
//   "크리스탈 게이트 강화",
//   "헥스 : 판데모니움 강화",
//   "보이드 버스트 강화",
//   "아츠 : 아스트라 강화",
//   "레조네이트 : 얼티메이텀 강화",
//   "근원의 기억 강화",
//   "인피니티 스펠 강화",
//   "새어 나오는 악몽/새어 나오는 흉몽 강화",
//   "영원히 굶주리는 짐승 강화",
//   "큰 기지개 강화",
//   "해 강 산 바람 강화",
//   "용솟음치는 정기 강화",
//   "산등성이 굽이굽이 강화",
//   "선기 : 극대 분신난무 강화",
//   "권술 : 산령소환 강화",
//   "선기 : 강림 괴력난신 강화",
//   "선기 : 천지인 환영 강화",
// ];

// const publicCore = ["솔 야누스"];
