import styles from "./Header.module.css";
import discord from "./img/disord.png";
import search from "./img/search.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Header() {
  const linkStyle = {
    textDecoration: "none", // Remove underline
    color: "inherit", // Inherit text color
    cursor: "pointer", // Change cursor to pointer on hover
  };
  const navigate = useNavigate();
  //api 호출 리턴값 상태값 이거 그냥 함수에서 한번에 객체로 만드는 거로 수정하자.
  const [characterName, setCharacterName] = useState("");
  const [basic, setBasic] = useState();
  const [popularity, setPopularity] = useState();
  const [stat, setStat] = useState();
  const [hyperStat, setHyperStat] = useState();
  const [propensity, setPropensity] = useState();
  const [ability, setAbility] = useState();
  const [itemEquipment, setItemEquipment] = useState();
  const [cashitemEquipment, setCashitemEquipment] = useState();
  const [symbolEquipment, setSymbolEquipment] = useState();
  const [setEffect, setSetEffect] = useState();
  const [beautyEquipment, setBeautyEquipment] = useState();
  const [androidEquipment, setAndroidEquipment] = useState();
  const [petEquipment, setPetEquipment] = useState();
  const [skill, setSkill] = useState();
  const [linkSkill, setLinkSkill] = useState();
  const [vmatrix, setVmatrix] = useState();
  const [hexamatrix, setHexamatrix] = useState();
  const [hexamatrixStat, setHexamatrixStat] = useState();
  const [dojang, setDojang] = useState();
  const [union, setUnion] = useState();
  const [unionRaider, setUnionRaider] = useState();
  const [wholeRanking, setWholeRanking] = useState();
  const [classRanking, setClassRanking] = useState();
  const [unionRanking, setUnionRanking] = useState();
  const [dojangRanking, setDojangRanking] = useState();
  const [theSeedRanking, setTheSeedRanking] = useState();
  const [achievementRanking, setAchievementRanking] = useState();
  const [unionArtifact, setUnionArtifact] = useState();
  //위의 데이터들을 종합한 객체
  const [characterInfo, setCharacterInfo] = useState({});

  //검색창 값 받아오기 위한 함수
  const onChange = (e) => {
    setCharacterName(e.target.value);
  };

  //전날 계산기
  const getYesterdayDate = () => {
    // 현재 날짜 객체 생성
    const currentDate = new Date();

    // 어제 날짜 계산
    const yesterdayDate = new Date(currentDate);
    yesterdayDate.setDate(currentDate.getDate() - 1);

    // 어제 날짜를 YYYY-MM-DD 형식으로 변환
    const formattedYesterday = yesterdayDate.toISOString().split("T")[0];

    return formattedYesterday;
  };

  //아래 함수는 캐릭터명을 받아온 후 api 호출 하여 결과값을 받아오는 함수들의 트랜잭션
  const fetchData = async () => {
    const API_KEY = process.env.REACT_APP_MAPLESTORY_API_KEY;
    try {
      // 첫 번째 API 호출 ( ocid )
      const response1 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/id?character_name=${characterName}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );

      const ocidData = await response1.json();
      if (ocidData.error) {
        alert("캐릭터를 찾을 수 없어요 ㅠ");
        console.log("에러 발생. fetchData를 중지합니다.");
        return;
      } else {
        alert("캐릭터 정보를 로딩중이에요~");
      }
      const ocid = ocidData.ocid;
      console.log("ocid=>", ocid);
      const yesterdayDate = getYesterdayDate();

      // 두 번째 API 호출 (캐릭터 기본 정보)
      const response2 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/basic?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );

      const basic = await response2.json();
      setBasic(basic);

      //인기도
      const response3 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/popularity?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const popularity = await response3.json();
      setPopularity(popularity);

      //스탯
      const response4 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/stat?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const stat = await response4.json();
      setStat(stat);

      //하이퍼 스탯
      const response5 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/hyper-stat?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const hyperStat = await response5.json();
      setHyperStat(hyperStat);

      //성향
      const response6 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/propensity?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const propensity = await response6.json();
      setPropensity(hyperStat);

      //어빌리티
      const response7 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/ability?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const ability = await response7.json();
      setAbility(ability);

      //장착 장비 정보
      const response8 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/item-equipment?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const itemEquipment = await response8.json();
      setItemEquipment(itemEquipment);

      //장착 캐시장비 정보
      const response9 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/cashitem-equipment?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const cashitemEquipment = await response9.json();
      setCashitemEquipment(cashitemEquipment);

      //장착 심볼 정보
      const response10 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/symbol-equipment?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const symbolEquipment = await response10.json();
      setSymbolEquipment(symbolEquipment);

      //장착 세트효과 정보
      const response11 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/set-effect?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const setEffect = await response11.json();
      setSetEffect(setEffect);

      //장착 헤어,성형,피부 정보
      const response12 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/beauty-equipment?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const beautyEquipment = await response12.json();
      setBeautyEquipment(beautyEquipment);

      //장착 안드로이드 정보
      const response13 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/android-equipment?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const androidEquipment = await response13.json();
      setAndroidEquipment(androidEquipment);

      //장착 펫 정보
      const response14 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/pet-equipment?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const petEquipment = await response14.json();
      setPetEquipment(petEquipment);

      //스킬 정보
      const response15 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/skill?ocid=${ocid}&date=${yesterdayDate}&character_skill_grade=6`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const skill = await response15.json();
      setSkill(skill);

      //링크스킬 정보
      const response16 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/link-skill?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const linkSkill = await response16.json();
      setLinkSkill(linkSkill);

      //v매트릭스 정보
      const response17 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/vmatrix?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const vmatrix = await response17.json();
      setVmatrix(vmatrix);

      //헥사코어 정보
      const response18 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/hexamatrix?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const hexamatrix = await response18.json();
      setHexamatrix(hexamatrix);

      //헥사스탯 정보
      const response19 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/hexamatrix-stat?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const hexamatrixStat = await response19.json();
      setHexamatrixStat(hexamatrixStat);

      //무릉도장 정보
      const response20 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/character/dojang?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const dojang = await response20.json();
      setDojang(dojang);

      //유니온 정보 (레벨 등)
      const response21 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/user/union?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const union = await response21.json();
      setUnion(union);

      //유니온 공격대 정보
      const response22 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/user/union-raider?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const unionRaider = await response22.json();
      setUnionRaider(unionRaider);

      //전체랭킹 정보
      const response23 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/ranking/overall?date=${yesterdayDate}&world_name=${basic.world_name}&ocid=${ocid}&page=1`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const wholeRanking = await response23.json();
      setWholeRanking(wholeRanking);

      //직업랭킹 정보
      const response24 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/ranking/overall?date=${yesterdayDate}&world_name=${
          basic.world_name
        }&class=${
          wholeRanking.ranking[0].class_name +
          "-" +
          wholeRanking.ranking[0].sub_class_name
        }&ocid=${ocid}&page=1`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const classRanking = await response24.json();
      setClassRanking(classRanking);

      //유니온랭킹 정보
      const response25 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/ranking/union?date=${yesterdayDate}&world_name=${basic.world_name}&ocid=${ocid}&page=1`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const unionRanking = await response25.json();
      setUnionRanking(unionRanking);

      //도장랭킹 정보
      const response26 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/ranking/dojang?date=${yesterdayDate}&world_name=${
          basic.world_name
        }&difficulty=0&class=${
          wholeRanking.ranking[0].class_name +
          "-" +
          wholeRanking.ranking[0].sub_class_name
        }&ocid=${ocid}&page=1`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const dojangRanking = await response26.json();
      setDojangRanking(dojangRanking);

      //더시드 랭킹 정보
      const response27 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/ranking/theseed?date=${yesterdayDate}&world_name=${basic.world_name}&ocid=${ocid}&page=1`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const theSeedRanking = await response27.json();
      setTheSeedRanking(theSeedRanking);

      //업적 랭킹 정보
      const response28 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/ranking/achievement?date=${yesterdayDate}&ocid=${ocid}&page=1`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const achievementRanking = await response28.json();
      setAchievementRanking(achievementRanking);

      //유니온 아티팩트 정보
      const response29 = await fetch(
        `https://open.api.nexon.com/maplestory/v1/user/union-artifact?ocid=${ocid}&date=${yesterdayDate}`,
        {
          headers: {
            "x-nxopen-api-key": API_KEY,
          },
        }
      );
      const unionArtifact = await response29.json();
      setUnionArtifact(unionArtifact);

      // 데이터를 받은 후에는 handleDataReceived 함수 호출
      handleDataReceived({
        basic,
        popularity,
        stat,
        hyperStat,
        propensity,
        ability,
        itemEquipment,
        cashitemEquipment,
        symbolEquipment,
        setEffect,
        beautyEquipment,
        androidEquipment,
        petEquipment,
        skill,
        linkSkill,
        vmatrix,
        hexamatrix,
        hexamatrixStat,
        dojang,
        union,
        unionRaider,
        wholeRanking,
        classRanking,
        unionRanking,
        dojangRanking,
        theSeedRanking,
        achievementRanking,
        unionArtifact,
        // ... (다른 데이터도 필요에 따라 추가)
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDataReceived = (data) => {
    // 데이터를 받은 후 처리하는 함수
    console.log("Data received:", data);
    setCharacterInfo(data);
    localStorage.clear();
    navigate("/search", { state: { characterInfo: data } });
    window.location.href = window.location.href;
  };

  const handleMainSubmit = (e) => {
    e.preventDefault();
    // 폼 제출 시 fetchData 함수 호출
    fetchData();
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.firstBox}>
        <div className={styles.logoBox}>
          <div>
            <Link to="/" style={linkStyle}>
              KARING.KR
            </Link>
          </div>
        </div>
        <div className={styles.searchBox}>
          <form onSubmit={handleMainSubmit}>
            <input
              placeholder="애정가득한 캐릭터명을 입력해보세요."
              onChange={onChange}
            />
          </form>
          <button type="submit" style={{ display: "none" }}></button>
          <img
            className={styles.searchIcon}
            src={search}
            onClick={handleMainSubmit}
            alt="검색 아이콘"
          ></img>
        </div>
        <div className={styles.discord}>
          <img src={discord} alt="디스코드 로고" width="60px"></img>
        </div>
      </div>
      <div className={styles.secondBox}>
        <div className={styles.eachMenu}>
          <Link to="/" style={linkStyle}>
            홈
          </Link>
        </div>
        {/* <div className={styles.eachMenu}>유니온 배치기</div> */}
        {/* <div className={styles.eachMenu}>랭킹</div>
        <div className={styles.eachMenu}>스타포스</div>*/}
        <div className={styles.eachMenu}>
          <Link to="/findCharacter" style={linkStyle}>
            {" "}
            본캐찾기
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
